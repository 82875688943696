<template>
  <div class="resource-center-preview">
    <!-- <headers></headers> -->
    <div class="tabs-box">
      <div class="tabs-left">
				<div>
        <!-- <p style="margin:0pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:宋体; font-size:18pt; font-weight:bold">目  录</span></p> -->
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><a
                style="color:#000000" href="#_Toc18664"><span style="font-family:仿宋; font-size:11pt">一、项目简介</span></a>
        </p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc4873"><span style="font-family:仿宋; font-size:11pt">（一）项目背景</span></a>
        </p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc26637"><span style="font-family:仿宋; font-size:11pt">（二）项目目标</span></a>
        </p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc2937"><span style="font-family:仿宋; font-size:11pt">（三）培训对象</span></a>
        </p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc17335"><span style="font-family:仿宋; font-size:11pt">（四）培训方式</span></a>
        </p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc21925"><span style="font-family:仿宋; font-size:11pt">（五）研修内容</span></a>
        </p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc18773"><span style="font-family:仿宋; font-size:11pt">（六） 管理机制</span></a>
        </p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc9610"><span style="font-family:Calibri; font-size:11pt">1. </span><span
                    style="font-family:仿宋; font-size:11pt">明确职责，推进任务有效落实</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc4109"><span style="font-family:Calibri; font-size:11pt">2. </span><span
                    style="font-family:仿宋; font-size:11pt">多方协同，保障项目顺利运行</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc9294"><span style="font-family:仿宋; font-size:11pt">（七）实施保障</span></a>
        </p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc14496"><span style="font-family:Calibri; font-size:11pt">1. </span><span
                    style="font-family:仿宋; font-size:11pt">项目管理团队</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc25365"><span style="font-family:Calibri; font-size:11pt">2. </span><span
                    style="font-family:仿宋; font-size:11pt">指导专家组</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc23908"><span style="font-family:Calibri; font-size:11pt">3. </span><span
                    style="font-family:仿宋; font-size:11pt">助教团队</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc12701"><span style="font-family:Calibri; font-size:11pt">4. </span><span
                    style="font-family:仿宋; font-size:11pt">平台及技术保障</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><a
                style="color:#000000" href="#_Toc6900"><span
                    style="font-family:仿宋; font-size:11pt">二、项目经费使用情况</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc24219"><span
                    style="font-family:仿宋; font-size:11pt">（一）财政资金到位率</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc16269"><span
                    style="font-family:仿宋; font-size:11pt">（二）资金实际支出情况</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><a
                style="color:#000000" href="#_Toc22582"><span style="font-family:仿宋; font-size:11pt">三、
                    项目组织实施情况</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc24114"><span
                    style="font-family:仿宋; font-size:11pt">（一）需求调研，“以需定案”</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc5386"><span
                    style="font-family:仿宋; font-size:11pt">（二）明确职责，有章可循</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc11274"><span
                    style="font-family:仿宋; font-size:11pt">（三）汇集资料，规范实施流程，保质高效</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc12959"><span
                    style="font-family:仿宋; font-size:11pt">（四）手册指导，操作演示，全面展示平台操作方法</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc408"><span
                    style="font-family:仿宋; font-size:11pt">（五）启动仪式，明确任务部署</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc23604"><span
                    style="font-family:仿宋; font-size:11pt">（六）全程任务提醒，不错过任何学习</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc26296"><span
                    style="font-family:仿宋; font-size:11pt">（七）建立工作群组，实时跟进项目进展，问题立即解决</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc25938"><span
                    style="font-family:仿宋; font-size:11pt">（八）运行监督，定期汇报</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><a
                style="color:#000000" href="#_Toc1929"><span style="font-family:仿宋; font-size:11pt">四、项目完成情况</span></a>
        </p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc7552"><span
                    style="font-family:仿宋; font-size:11pt">（一）严格执行标准，各学科课程圆满完成</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc8625"><span
                    style="font-family:仿宋; font-size:11pt">（二）多种互动方式，打破课堂沉默</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc20240"><span
                    style="font-family:仿宋; font-size:11pt">（三）多种主题案例活动，促进理论向实践转化</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc13457"><span style="font-family:仿宋; font-size:11pt">（</span><span
                    style="font-family:仿宋; font-size:11pt">四</span><span
                    style="font-family:仿宋; font-size:11pt">）培训收获，全面提升</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc10464"><span style="font-family:Calibri; font-size:11pt">1. </span><span
                    style="font-family:仿宋; font-size:11pt">学习与观念改变</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc21616"><span style="font-family:Calibri; font-size:11pt">2. </span><span
                    style="font-family:仿宋; font-size:11pt">课程与教材理解更加深刻</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc4985"><span style="font-family:Calibri; font-size:11pt">3. </span><span
                    style="font-family:仿宋; font-size:11pt">教学设计与实施能力提升</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc16292"><span style="font-family:Calibri; font-size:11pt">4. </span><span
                    style="font-family:仿宋; font-size:11pt">教学研究与教师成长</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc13315"><span style="font-family:仿宋; font-size:11pt">（</span><span
                    style="font-family:仿宋; font-size:11pt">五</span><span
                    style="font-family:仿宋; font-size:11pt">）种子教师培养，人才储备</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><a
                style="color:#000000" href="#_Toc3638"><span style="font-family:仿宋; font-size:11pt">五、项目效益</span></a>
        </p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc13274"><span style="font-family:仿宋; font-size:11pt">（一）教育效能</span></a>
        </p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc22438"><span style="font-family:Calibri; font-size:11pt">1. </span><span
                    style="font-family:仿宋; font-size:11pt">教育理念创新</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc22987"><span style="font-family:Calibri; font-size:11pt">2. </span><span
                    style="font-family:仿宋; font-size:11pt">促进课堂形态变革</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc18349"><span style="font-family:Calibri; font-size:11pt">3. </span><span
                    style="font-family:仿宋; font-size:11pt">建立新型师生关系</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc10822"><span style="font-family:仿宋; font-size:11pt">（二）角色效能</span></a>
        </p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc8753"><span style="font-family:Calibri; font-size:11pt">1. </span><span
                    style="font-family:仿宋; font-size:11pt">面向教师</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc11777"><span style="font-family:Calibri; font-size:11pt">2. </span><span
                    style="font-family:仿宋; font-size:11pt">面向学校</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc30667"><span style="font-family:Calibri; font-size:11pt">3. </span><span
                    style="font-family:仿宋; font-size:11pt">面向教育管理者</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc15080"><span style="font-family:仿宋; font-size:11pt">（三）社会效能</span></a>
        </p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc6034"><span style="font-family:Calibri; font-size:11pt">1. </span><span
                    style="font-family:仿宋; font-size:11pt">实现教学与管理减负增效</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc14664"><span style="font-family:Calibri; font-size:11pt">2. </span><span
                    style="font-family:仿宋; font-size:11pt">辐射引领发展</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc28666"><span style="font-family:Calibri; font-size:11pt">3. </span><span
                    style="font-family:仿宋; font-size:11pt">教育精准扶贫</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc28567"><span
                    style="font-family:仿宋; font-size:11pt">（四）项目满意度调查</span></a></p>
        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"></p>
    </div>
			</div>
      <div class="tabs-right">
				<div>
        <p style="font-size:16pt; line-height:100%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><a
                name="_Toc2160"><span style="font-family:黑体; font-size:16pt; font-weight:bold">&#xa0;</span></a></p>
        <p style="font-size:16pt; line-height:100%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:黑体; font-size:16pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:16pt; line-height:100%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:黑体; font-size:16pt; font-weight:bold">“首都带首府”“互联网+教育”首都师范大学双优云桥项目</span></p>
        <!-- <p style="font-size:28pt; line-height:200%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:黑体; font-size:28pt; font-weight:bold"></span></p> -->
        <p style="font-size:16pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:黑体; font-size:16pt; font-weight:bold">“</span><span
                style="font-family:黑体; font-size:16pt; font-weight:bold">名校联盟 双师课堂 </span><span
                style="font-family:黑体; font-size:16pt; font-weight:bold">”绩效报告</span></p>
        <!-- <p style="font-size:28pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:黑体; font-size:28pt; font-weight:bold"></span></p> -->
        <!-- <p style="font-size:20pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:黑体; font-size:20pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:16pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
                style="font-family:黑体; font-size:16pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:16pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
                style="font-family:黑体; font-size:16pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:16pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
                style="font-family:黑体; font-size:16pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:16pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
                style="font-family:黑体; font-size:16pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:16pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
                style="font-family:黑体; font-size:16pt; font-weight:bold">&#xa0;</span></p> -->
        <!-- <p
            style="font-size:16pt; line-height:200%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:70pt; widows:0">
            <span style="font-family:黑体; font-size:16pt; font-weight:bold">项目</span><span
                style="font-family:黑体; font-size:16pt; font-weight:bold">：</span><span
                style="font-family:黑体; font-size:16pt; font-weight:bold">名校联盟 双师课堂</span></p>
        <p
            style="font-size:16pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:70pt; widows:0">
            <span style="font-family:黑体; font-size:16pt; font-weight:bold">单位：乐智教育研究院</span></p>
        <p
            style="font-size:16pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:70pt; widows:0">
            <span style="font-family:黑体; font-size:16pt; font-weight:bold">时间：202</span><span
                style="font-family:黑体; font-size:16pt; font-weight:bold">1</span><span
                style="font-family:黑体; font-size:16pt; font-weight:bold">.0</span><span
                style="font-family:黑体; font-size:16pt; font-weight:bold">1</span><span
                style="font-family:黑体; font-size:16pt; font-weight:bold">.</span><span
                style="font-family:黑体; font-size:16pt; font-weight:bold">05</span></p> -->
    </div>
    <!-- <div>
        <p style="margin:0pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:宋体; font-size:18pt; font-weight:bold">目  录</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><a
                style="color:#000000" href="#_Toc18664"><span style="font-family:仿宋; font-size:14pt">一、项目简介</span></a>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc4873"><span style="font-family:仿宋; font-size:14pt">（一）项目背景</span></a>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc26637"><span style="font-family:仿宋; font-size:14pt">（二）项目目标</span></a>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc2937"><span style="font-family:仿宋; font-size:14pt">（三）培训对象</span></a>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc17335"><span style="font-family:仿宋; font-size:14pt">（四）培训方式</span></a>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc21925"><span style="font-family:仿宋; font-size:14pt">（五）研修内容</span></a>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc18773"><span style="font-family:仿宋; font-size:14pt">（六） 管理机制</span></a>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc9610"><span style="font-family:Calibri; font-size:14pt">1. </span><span
                    style="font-family:仿宋; font-size:14pt">明确职责，推进任务有效落实</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc4109"><span style="font-family:Calibri; font-size:14pt">2. </span><span
                    style="font-family:仿宋; font-size:14pt">多方协同，保障项目顺利运行</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc9294"><span style="font-family:仿宋; font-size:14pt">（七）实施保障</span></a>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc14496"><span style="font-family:Calibri; font-size:14pt">1. </span><span
                    style="font-family:仿宋; font-size:14pt">项目管理团队</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc25365"><span style="font-family:Calibri; font-size:14pt">2. </span><span
                    style="font-family:仿宋; font-size:14pt">指导专家组</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc23908"><span style="font-family:Calibri; font-size:14pt">3. </span><span
                    style="font-family:仿宋; font-size:14pt">助教团队</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc12701"><span style="font-family:Calibri; font-size:14pt">4. </span><span
                    style="font-family:仿宋; font-size:14pt">平台及技术保障</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><a
                style="color:#000000" href="#_Toc6900"><span
                    style="font-family:仿宋; font-size:14pt">二、项目经费使用情况</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc24219"><span
                    style="font-family:仿宋; font-size:14pt">（一）财政资金到位率</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc16269"><span
                    style="font-family:仿宋; font-size:14pt">（二）资金实际支出情况</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><a
                style="color:#000000" href="#_Toc22582"><span style="font-family:仿宋; font-size:14pt">三、
                    项目组织实施情况</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc24114"><span
                    style="font-family:仿宋; font-size:14pt">（一）需求调研，“以需定案”</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc5386"><span
                    style="font-family:仿宋; font-size:14pt">（二）明确职责，有章可循</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc11274"><span
                    style="font-family:仿宋; font-size:14pt">（三）汇集资料，规范实施流程，保质高效</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc12959"><span
                    style="font-family:仿宋; font-size:14pt">（四）手册指导，操作演示，全面展示平台操作方法</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc408"><span
                    style="font-family:仿宋; font-size:14pt">（五）启动仪式，明确任务部署</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc23604"><span
                    style="font-family:仿宋; font-size:14pt">（六）全程任务提醒，不错过任何学习</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc26296"><span
                    style="font-family:仿宋; font-size:14pt">（七）建立工作群组，实时跟进项目进展，问题立即解决</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc25938"><span
                    style="font-family:仿宋; font-size:14pt">（八）运行监督，定期汇报</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><a
                style="color:#000000" href="#_Toc1929"><span style="font-family:仿宋; font-size:14pt">四、项目完成情况</span></a>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc7552"><span
                    style="font-family:仿宋; font-size:14pt">（一）严格执行标准，各学科课程圆满完成</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc8625"><span
                    style="font-family:仿宋; font-size:14pt">（二）多种互动方式，打破课堂沉默</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc20240"><span
                    style="font-family:仿宋; font-size:14pt">（三）多种主题案例活动，促进理论向实践转化</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc13457"><span style="font-family:仿宋; font-size:14pt">（</span><span
                    style="font-family:仿宋; font-size:14pt">四</span><span
                    style="font-family:仿宋; font-size:14pt">）培训收获，全面提升</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc10464"><span style="font-family:Calibri; font-size:14pt">1. </span><span
                    style="font-family:仿宋; font-size:14pt">学习与观念改变</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc21616"><span style="font-family:Calibri; font-size:14pt">2. </span><span
                    style="font-family:仿宋; font-size:14pt">课程与教材理解更加深刻</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc4985"><span style="font-family:Calibri; font-size:14pt">3. </span><span
                    style="font-family:仿宋; font-size:14pt">教学设计与实施能力提升</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc16292"><span style="font-family:Calibri; font-size:14pt">4. </span><span
                    style="font-family:仿宋; font-size:14pt">教学研究与教师成长</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc13315"><span style="font-family:仿宋; font-size:14pt">（</span><span
                    style="font-family:仿宋; font-size:14pt">五</span><span
                    style="font-family:仿宋; font-size:14pt">）种子教师培养，人才储备</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><a
                style="color:#000000" href="#_Toc3638"><span style="font-family:仿宋; font-size:14pt">五、项目效益</span></a>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc13274"><span style="font-family:仿宋; font-size:14pt">（一）教育效能</span></a>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc22438"><span style="font-family:Calibri; font-size:14pt">1. </span><span
                    style="font-family:仿宋; font-size:14pt">教育理念创新</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc22987"><span style="font-family:Calibri; font-size:14pt">2. </span><span
                    style="font-family:仿宋; font-size:14pt">促进课堂形态变革</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc18349"><span style="font-family:Calibri; font-size:14pt">3. </span><span
                    style="font-family:仿宋; font-size:14pt">建立新型师生关系</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc10822"><span style="font-family:仿宋; font-size:14pt">（二）角色效能</span></a>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc8753"><span style="font-family:Calibri; font-size:14pt">1. </span><span
                    style="font-family:仿宋; font-size:14pt">面向教师</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc11777"><span style="font-family:Calibri; font-size:14pt">2. </span><span
                    style="font-family:仿宋; font-size:14pt">面向学校</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc30667"><span style="font-family:Calibri; font-size:14pt">3. </span><span
                    style="font-family:仿宋; font-size:14pt">面向教育管理者</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc15080"><span style="font-family:仿宋; font-size:14pt">（三）社会效能</span></a>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc6034"><span style="font-family:Calibri; font-size:14pt">1. </span><span
                    style="font-family:仿宋; font-size:14pt">实现教学与管理减负增效</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc14664"><span style="font-family:Calibri; font-size:14pt">2. </span><span
                    style="font-family:仿宋; font-size:14pt">辐射引领发展</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc28666"><span style="font-family:Calibri; font-size:14pt">3. </span><span
                    style="font-family:仿宋; font-size:14pt">教育精准扶贫</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <a style="color:#000000" href="#_Toc28567"><span
                    style="font-family:仿宋; font-size:14pt">（四）项目满意度调查</span></a></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"></p>
    </div> -->
		<!-- <br style="clear:both; mso-break-type:section-break; page-break-before:always" /> -->
    <div>
        <h1
            style="font-size:14pt; line-height:150%; margin:17pt 0pt 16.5pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc18664"><span style="font-family:仿宋; font-size:14pt; font-weight:bold">一、项目简介</span><span
                    style="-aw-bookmark-end:_Toc2160"></span></a></h1>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc4873"><span style="font-family:仿宋; font-size:14pt; font-weight:bold">（一）项目背景</span></a></h2>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:24pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">“名校联盟
                双师课堂”项目是“首都教育远程互助工程”的核心项目之一，由首都师范大学教师教育学院博士生导师张景斌教授带领相关学科专家团队负责本项目的实施及统筹工作。</span><span
                style="font-family:仿宋; font-size:14pt">项目参照《教育部关于加强“三个课堂”应用的指导意见》，“双优云桥—</span><span
                style="font-family:仿宋; font-size:14pt">校联盟 双师课堂</span><span
                style="font-family:仿宋; font-size:14pt">”项目根据调研需求为该项目在银川市的实施制定了三年规划，以重点提升教师教育教学能力为核心开展“双师课堂”，构建在“做中学”的“双师课堂”研修体系，搭建辅助教师能力发展平台。在引入优质教育资源的同时，银川老师通过线上与线下相结合，主题研讨与教学改进相结合，名校课堂观摩与教学能力提升研修相结合的方式，实现双师模式中“辅教”变“主教”的角色转换，使银川本地教师有更多的获得感，从而构建“授之以渔”的教育均衡化新模式，实现从“输血”到“造血”的机制转变；此外，基于深度的学生学情调研，引入符合银川学生实际的首都师范大学附小、史家胡同小学等名校名师优质课程，摒弃以往不顾学情而生硬引入教育资源的做法，做到“引得入，用得住”。</span>
        </p>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc26637"><span style="font-family:仿宋; font-size:14pt; font-weight:bold">（二）项目目标</span></a></h2>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:28pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">认真遵行银川市政府与首都师范大学签订的《“首都带首府”京银“互联网+教育”战略合作框架协议》，严格遵照《“首都带首府”京银“互联网+教育”双优云桥项目》建设要求执行，积极推进“</span><span
                style="font-family:仿宋; font-size:14pt">校联盟 双师课堂</span><span
                style="font-family:仿宋; font-size:14pt">”创新模式与教育教学融合发展，通过“</span><span
                style="font-family:仿宋; font-size:14pt">校联盟 双师课堂</span><span
                style="font-family:仿宋; font-size:14pt">”的模式发挥优质教育资源的普惠效应，提升项目校乃至银川市教师整体的专业发展水平，促进项目校治理体制和校园文化建设，使银川市基础教育质量产生质的飞跃。为此，提出“努力实现‘三个提升’，全力推进‘六个目标’”项目实施举措，落实工作责任，进一步完善项目实施。</span>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.001.png" width="570" height="107" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：项目目标规划</span></p>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt; font-weight:bold">（</span><span
                style="font-family:Arial; font-size:14pt; font-weight:bold">3</span><span
                style="font-family:仿宋; font-size:14pt; font-weight:bold">）</span><a name="_Toc2937"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">培训对象</span></a></h2>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
                style="font-family:仿宋; font-size:14pt">    银川37所项目校（小学21所、初中16所），共450名教师</span><span
                style="font-family:仿宋; font-size:14pt">。</span></p>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc17335"><span style="font-family:仿宋; font-size:14pt; font-weight:bold">（四）培训方式</span></a></h2>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">“</span><span style="font-family:仿宋; font-size:14pt">校联盟
                双师课堂</span><span
                style="font-family:仿宋; font-size:14pt">”项目实施活动将突出五个结合：理论与实践相结合，线上与线下活动相结合，主题研讨与教学改进相结合，相互交流与自我反思相结合，项目推进与区域（学校）支持相结合，努力构建“双师课堂”创新模式。</span>
        </p>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc21925"><span style="font-family:仿宋; font-size:14pt; font-weight:bold">（五）研修内容</span></a></h2>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
                style="font-family:仿宋; font-size:14pt">   
                因疫情原因，项目组及时调整实施策略，临时将线下课程全部转移至线上教学。通过立体式的课程体系将设计丰富有趣的教学活动及教学任务，提升师生学习兴趣的同时注重教师教学能力发展和学生学习能力的塑造。</span>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.002.png" width="462" height="260" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：研修工作重点</span></p>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt; font-weight:bold">（</span><span
                style="font-family:Arial; font-size:14pt; font-weight:bold">6</span><span
                style="font-family:仿宋; font-size:14pt; font-weight:bold">）</span><a name="_Toc18773"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">管理机制</span></a></h2>
        <h3
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:Calibri; font-size:14pt; font-weight:bold">1.</span><span
                style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span><a name="_Toc9610"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">明确职责，推进任务有效落实</span></a></h3>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span
                style="font-family:仿宋; font-size:14pt">制定《学科指导组工作职责》《助教工作职责》《项目组工作细则》，项目成员各司其职，严格按照实施方案，推进各项任务有效落实。</span>
        </p>
        <h3
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:Calibri; font-size:14pt; font-weight:bold">2.</span><span
                style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span><a name="_Toc4109"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">多方协同，保障项目顺利运行</span></a></h3>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span
                style="font-family:仿宋; font-size:14pt">坚持目标导向，强化监督管理，平台实时监控学习进度，学科助教关注学习状态，项目组定期汇报培训情况，多方协同保证培训质量，提升培训效益。</span>
        </p>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc9294"><span style="font-family:仿宋; font-size:14pt; font-weight:bold">（七）实施保障</span></a></h2>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:28pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">做为“名校联盟 双师课堂”项目的承办单位，为了保障项目能够满足教师培训需求，公司成立专门的项目领导小组，</span><span
                style="font-family:仿宋; font-size:14pt">制订了详细周密的工作计划，</span><span
                style="font-family:仿宋; font-size:14pt">统筹管理项目的运行工作，</span><span
                style="font-family:仿宋; font-size:14pt">为此专门建立了培训团队服务于本次培训，包括项目领导组、专家团队、助教团队、技术团队。</span></p>
        <h3
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:Calibri; font-size:14pt; font-weight:bold">1.</span><span
                style="font:7.0pt 'Times New Roman'">&#xa0; </span><a name="_Toc14496"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">项目管理团队</span></a></h3>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
                style="font-family:仿宋; font-size:14pt">（1）项目负责人小组：北京首都师范大学、银川市教育局、“名校联盟 双师课堂”项目组</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
                style="font-family:仿宋; font-size:14pt">（2）项目运营组：受项目负责人领导，接受专家团队的业务指导，对研修平台进行维护、优化，对研修全程进行监控、协调、管理。</span>
        </p>
        <h3
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:Calibri; font-size:14pt; font-weight:bold">2.</span><span
                style="font:7.0pt 'Times New Roman'">&#xa0; </span><a name="_Toc25365"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">指导专家组</span></a></h3>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:28pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">为了保障 “名校联盟
                双师课堂”项目能够满足银川市教师培训需求，拥有专业化学科指导专家团队，提供本地化、精细化、针对性的教学服务工作，是做好教师培训工作的保障。项目组针对此次培训的四个学科，分别建立具有学科性的高专业水平的学科指导专业团队。每学科的指导组成员包括大学学科教育专家（1名）和中小学特级教师或正高级教师（2名）及若干名具有丰富教学经验及专业知识的一线学科教师共计百余位学科专家。这是一只拥有专业化教学服务支持团队，提供本地化、精细化、针对性的教学服务工作，是做好教师培训工作的保障。使培训方案更接地气，培训内容更具针对性，培训效果更具实效性。</span>
        </p>
        <h3
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:Calibri; font-size:14pt; font-weight:bold">3.</span><span
                style="font:7.0pt 'Times New Roman'">&#xa0; </span><a name="_Toc23908"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">助教团队</span></a></h3>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span
                style="font-family:仿宋; font-size:14pt">为保证培训项目的顺利进行，以及能够给各位专家和参训教师提供专业高品质的支持服务，项目在组建助教团队时，将具有专业学科背景的研究生作为进入助教候选资格的限制条件且对候选助教进行严格的筛选，并对助教团队进行标准系统性的培训，最后考核通过才能进行助教实习工作，并在工作中会对助教的表现和服务进行监督。</span>
        </p>
        <h3
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:Calibri; font-size:14pt; font-weight:bold">4.</span><span
                style="font:7.0pt 'Times New Roman'">&#xa0; </span><a name="_Toc12701"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">平台及技术保障</span></a></h3>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">由于项目特殊性以及受疫情影响，“</span><span
                style="font-family:仿宋; font-size:14pt">校联盟 双师课堂</span><span
                style="font-family:仿宋; font-size:14pt">”项目采用线上形式进行，为了保证课程以及各活动的顺利进行，项目组成立了专门的技术保障团队，随时为项目全程提供全方位的服务和支持，为项目的顺利开展保驾护航。技术团队受项目负责人领导，随时准备服务支持工作。</span>
        </p>
        <h1
            style="font-size:14pt; line-height:150%; margin:17pt 0pt 16.5pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc12006"></a><a name="_Toc6900"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">二、项目经费使用情况</span><span
                    style="-aw-bookmark-end:_Toc12006"></span></a></h1>
        <h2
            style="font-size:14pt; line-height:150%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc22174"></a><a name="_Toc24219"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">（一）财政资金到位率</span><span
                    style="-aw-bookmark-end:_Toc22174"></span></a></h2>
        <div style="text-align:center">
            <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:0 auto; width:94.64%">
                <tr style="height:31.2pt">
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:62.46%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:仿宋; font-size:12pt; font-weight:bold">名称</span></p>
                    </td>
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:37.52%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:仿宋; font-size:12pt; font-weight:bold">价格/万元</span></p>
                    </td>
                </tr>
                <tr style="height:31.2pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:62.46%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">计划投入财政资金</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:37.52%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                </tr>
                <tr style="height:31.2pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:62.46%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">实际到位财政资金</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:37.52%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                </tr>
                <tr style="height:31.2pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:62.46%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">财政资金到位率</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:37.52%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                </tr>
            </table>
        </div>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
        <h2
            style="font-size:14pt; line-height:150%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc16269"></a><a name="_Toc8509"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">（二）资金实际支出情况</span><span
                    style="-aw-bookmark-end:_Toc16269"></span></a></h2>
        <div style="text-align:center">
            <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:0 auto; width:100%">
                <tr style="height:31.2pt">
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:30.2%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:仿宋; font-size:12pt; font-weight:bold">项目</span></p>
                    </td>
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:13.24%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:仿宋; font-size:12pt; font-weight:bold">资金</span></p>
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:仿宋; font-size:12pt; font-weight:bold">总数</span></p>
                    </td>
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:仿宋; font-size:12pt; font-weight:bold">实际</span></p>
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:仿宋; font-size:12pt; font-weight:bold">使用</span></p>
                    </td>
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:仿宋; font-size:12pt; font-weight:bold">结余</span></p>
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:仿宋; font-size:12pt; font-weight:bold">情况</span></p>
                    </td>
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:仿宋; font-size:12pt; font-weight:bold">完成率</span></p>
                    </td>
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:14.1%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:仿宋; font-size:12pt; font-weight:bold">备注</span></p>
                    </td>
                </tr>
                <tr style="height:31.2pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:30.2%">
                        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify"><span
                                style="font-family:仿宋; font-size:12pt">专家调研费、方案研制费</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:13.24%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.1%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                </tr>
                <tr style="height:17.85pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:30.2%">
                        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify"><span
                                style="font-family:仿宋; font-size:12pt">首席专家费用</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:13.24%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.1%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                </tr>
                <tr style="height:31.2pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:30.2%">
                        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify"><span
                                style="font-family:仿宋; font-size:12pt">学科指导专家费用</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:13.24%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.1%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                </tr>
                <tr style="height:31.2pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:30.2%">
                        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify"><span
                                style="font-family:仿宋; font-size:12pt">主讲专家费用</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:13.24%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.1%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                </tr>
                <tr style="height:31.2pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:30.2%">
                        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify"><span
                                style="font-family:仿宋; font-size:12pt">项目期间相关活动费</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:13.24%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.1%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                </tr>
                <tr style="height:18.95pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:30.2%">
                        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify"><span
                                style="font-family:仿宋; font-size:12pt">网络服务通道费</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:13.24%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.1%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                </tr>
                <tr style="height:31.2pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:30.2%">
                        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify"><span
                                style="font-family:仿宋; font-size:12pt">项目维护相关费用（实施人员费、技术维护人员费</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:13.24%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.1%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                </tr>
                <tr style="height:31.2pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:30.2%">
                        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify"><span
                                style="font-family:仿宋; font-size:12pt">学科助教费用</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:13.24%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.1%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                </tr>
                <tr style="height:20.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:30.2%">
                        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify"><span
                                style="font-family:仿宋; font-size:12pt">项目管理费</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:13.24%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.1%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                </tr>
                <tr style="height:31.2pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:30.2%">
                        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify"><span
                                style="font-family:仿宋; font-size:12pt">数据分析费用</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:13.24%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.1%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                </tr>
                <tr style="height:31.2pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:30.2%">
                        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify"><span
                                style="font-family:仿宋; font-size:12pt">资源整理及物化成果汇编费</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:13.24%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.1%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                </tr>
                <tr style="height:31.2pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:30.2%">
                        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:12pt; font-weight:bold">合计</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:13.24%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.12%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:14.1%">
                        <p
                            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:justify; text-indent:24pt">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                </tr>
            </table>
        </div>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><a
                name="_Toc9793"><span style="font-family:仿宋; font-size:14pt">&#xa0;</span></a></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
        <h1
            style="font-size:14pt; line-height:150%; margin:17pt 0pt 16.5pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt; font-weight:bold">3、</span><a name="_Toc22582"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">项目组织实施情况</span><span
                    style="-aw-bookmark-end:_Toc9793"></span></a><a name="_Toc10752"></a></h1>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:28pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">为推进“名校联盟 双师课堂”项目的有效开展与实施，</span><span
                style="font-family:仿宋; font-size:14pt">在整个培训过程中，项目组全程参与项目的提供全流程的支持与服务，为学员能够正常、有序地在进行学习保驾护航。</span></p>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc24114"><span style="font-family:仿宋; font-size:14pt; font-weight:bold">（一）需求调研，“以需定案”</span></a>
        </h2>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:28pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">项目筹备前期，为了制定符合银川当地学情和发展特点的培训方案，项目组以张景斌教授为首的专家团队对银川37所项目校以及全体参训教师进行深度调研。从参训教师个体，到项目校整体，“点面结合”全方位了解情况；从教师个人专业能力，到项目校教材使用、硬件设备，“软硬兼顾”。通过调研，掌握银川地区项目校及参训教师的第一手扎实资料形成调研报告，与专家组进行深入沟通了解参训教师教学方面的迫切需求及遇到的实际困难，开发、完善满足这些需求层面的解决方案，合理安排课程。提高培训的针对性、实用性、可借鉴性，保障参训教师需有所学，学以致用，用有提升。</span>
        </p>
        <p style="font-size:14pt; text-align: center;line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.003.png" width="558" height="392" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-align:center; text-indent:20pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">图：</span><span
                style="font-family:仿宋; font-size:14pt">项目校参训教师调研报告</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.004.png" width="432" height="206" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.005.png" width="434" height="204" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：</span><span
                style="font-family:仿宋; font-size:14pt">项目校教材使用及学生分布调研报告</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.006.png" width="534" height="380" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：</span><span
                style="font-family:仿宋; font-size:14pt">项目校智慧教室硬件设备调研报告</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.007.png" width="460" height="422" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：项目实施方案</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.008.png" width="264" height="332" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：</span><span
                style="font-family:仿宋; font-size:14pt">学科课程安排</span></p>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc5386"><span style="font-family:仿宋; font-size:14pt; font-weight:bold">（二）明确职责，有章可循</span></a>
        </h2>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:24pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">为更好的保障“名校联盟
                双师课堂”项目的顺利运行，项目组统筹管理项目的运行工作，明确各环节的职责分工，加强实施过程的监管力度，每一环节的工作都落实到人，形成闭环，不留空白。</span></p>
        <ol type="1" style="margin:0pt; padding-left:0pt">
            <li
                style="font-family:仿宋; font-size:14pt; font-weight:bold; line-height:150%; margin:0pt 0pt 10pt 19pt; orphans:0; padding-left:2.25pt; text-indent:0pt; widows:0">
                <span style="font-family:仿宋; font-size:14pt; font-weight:bold">项目管理团队（领导小组）主要职责</span></li>
        </ol>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21.25pt; orphans:0; text-indent:-21.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">(1)</span><span style="font:7.0pt 'Times New Roman'">
            </span><span style="font-family:仿宋; font-size:14pt">负责项目的统筹管理、全面指导；</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21.25pt; orphans:0; text-indent:-21.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">(2)</span><span style="font:7.0pt 'Times New Roman'">
            </span><span style="font-family:仿宋; font-size:14pt">协调与支持学科指导团队建设；</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21.25pt; orphans:0; text-indent:-21.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">(3)</span><span style="font:7.0pt 'Times New Roman'">
            </span><span style="font-family:仿宋; font-size:14pt">研修的过程管理与监控（对培训效果情况进行及时跟进、根据疫情形势对培训政策及方式做出适时调整等）。</span>
        </p>
        <ol start="2" type="1" style="margin:0pt; padding-left:0pt">
            <li
                style="font-family:仿宋; font-size:14pt; font-weight:bold; line-height:150%; margin:0pt 0pt 10pt 19pt; orphans:0; padding-left:2.25pt; text-indent:0pt; widows:0">
                <span style="font-family:仿宋; font-size:14pt; font-weight:bold">项目运营组主要职责</span></li>
        </ol>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21.25pt; orphans:0; text-indent:-21.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">(1)</span><span style="font:7.0pt 'Times New Roman'">
            </span><span style="font-family:仿宋; font-size:14pt">在项目实施前，在领导组的指挥下进行项目前期的筹备工作，具体表现为：</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">1　</span><span
                style="font-family:仿宋; font-size:14pt">根据项目要求制定详细项目时间规划；</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">2　</span><span
                style="font-family:仿宋; font-size:14pt">与学科专家指导组对接培训活动计划并准备落实实施工作；</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">3　</span><span
                style="font-family:仿宋; font-size:14pt">项目标准化实施流程的建立与梳理；</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">4　</span><span
                style="font-family:仿宋; font-size:14pt">根据项目需求进行助教团队建设与培训；</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">5　</span><span
                style="font-family:仿宋; font-size:14pt">参训学员信息收集与平台搭建工作。</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21.25pt; orphans:0; text-indent:-21.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">(2)</span><span style="font:7.0pt 'Times New Roman'">
            </span><span
                style="font-family:仿宋; font-size:14pt">在项目实施中，项目运营组需要对项目的各个环节进行参与和把控，及时上报项目状态，并随时给专家团队提供支持，具体表现为：</span>
        </p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">1　</span><span
                style="font-family:仿宋; font-size:14pt">按照项目按照既定方案落实项目实施工作；</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">2　</span><span
                style="font-family:仿宋; font-size:14pt">培训过程及质量监控；</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">3　</span><span
                style="font-family:仿宋; font-size:14pt">助教团队的管理（工作考核、任务验收、不定期培训等）；</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">4　</span><span
                style="font-family:仿宋; font-size:14pt">培训数据的收集及整理和各类简报的撰写；</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">5　</span><span
                style="font-family:仿宋; font-size:14pt">培训期间为各类活动提供保障支持。</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21.25pt; orphans:0; text-indent:-21.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">(3)</span><span style="font:7.0pt 'Times New Roman'">
            </span><span style="font-family:仿宋; font-size:14pt">在项目实施后，在专家组及项目领导组的带领下进行项目的收尾及总结工作，具体表现为：</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">1　</span><span
                style="font-family:仿宋; font-size:14pt">协助专家进行成果提炼；</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">2　</span><span
                style="font-family:仿宋; font-size:14pt">参训学员的学习数据整理统计及各类报告的撰写；</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">3　</span><span
                style="font-family:仿宋; font-size:14pt">项目文件的整理、各类培训资源的收集归档等。</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.009.png" width="552" height="318" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt; font-weight:normal">图：</span><span
                style="font-family:仿宋; font-size:14pt; font-weight:normal">项目组职责文件</span></p>
        <ol start="3" type="1" style="margin:0pt; padding-left:0pt">
            <li
                style="font-family:仿宋; font-size:14pt; font-weight:bold; line-height:150%; margin:0pt 0pt 10pt 19pt; orphans:0; padding-left:2.25pt; text-indent:0pt; widows:0">
                <span style="font-family:仿宋; font-size:14pt; font-weight:bold">指导专家主要职责</span></li>
        </ol>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21.25pt; orphans:0; text-indent:-21.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">(1)</span><span style="font:7.0pt 'Times New Roman'">
            </span><span style="font-family:仿宋; font-size:14pt">与银川方面协同开展培训需求调研和方案研制；</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21.25pt; orphans:0; text-indent:-21.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">(2)</span><span style="font:7.0pt 'Times New Roman'">
            </span><span style="font-family:仿宋; font-size:14pt">设计每学期本学科教师研修培训活动计划及设计具有科学针对性的课程体系；</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21.25pt; orphans:0; text-indent:-21.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">(3)</span><span style="font:7.0pt 'Times New Roman'">
            </span><span style="font-family:仿宋; font-size:14pt">组建课程授课团队并提供专业化指导，包括：帮助授课教师制定符合本项目的授课主题、课后作业规划等；</span>
        </p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21.25pt; orphans:0; text-indent:-21.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">(4)</span><span style="font:7.0pt 'Times New Roman'">
            </span><span style="font-family:仿宋; font-size:14pt">组织实施本学科组研修培训计划；</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21.25pt; orphans:0; text-indent:-21.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">(5)</span><span style="font:7.0pt 'Times New Roman'">
            </span><span
                style="font-family:仿宋; font-size:14pt">为学科骨干教师（2名）发展提供个性化指导，具体包括：帮助其找到发展的突破口；提升其教学实践能力；使其对一些教育教学问题的思考有深度、能言说，最终能体现于教学展示课中；</span>
        </p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21.25pt; orphans:0; text-indent:-21.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">(6)</span><span style="font:7.0pt 'Times New Roman'">
            </span><span style="font-family:仿宋; font-size:14pt">对学员的作业进行必要点评、反馈；</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21.25pt; orphans:0; text-indent:-21.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">(7)</span><span style="font:7.0pt 'Times New Roman'">
            </span><span style="font-family:仿宋; font-size:14pt">指导助教开展工作。</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.010.png" width="556" height="310" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：</span><span
                style="font-family:仿宋; font-size:14pt">指导专家职责文件</span></p>
        <ol start="4" type="1" style="margin:0pt; padding-left:0pt">
            <li
                style="font-family:仿宋; font-size:14pt; font-weight:bold; line-height:150%; margin:0pt 0pt 10pt 19pt; orphans:0; padding-left:2.25pt; text-indent:0pt; widows:0">
                <span style="font-family:仿宋; font-size:14pt; font-weight:bold">助教团队主要职责</span></li>
        </ol>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21.25pt; orphans:0; text-align:justify; text-indent:-21.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">(1)</span><span style="font:7.0pt 'Times New Roman'">
            </span><span style="font-family:仿宋; font-size:14pt">在项目实施前，协助项目组进行项目前期的筹备工作，具体表现为</span><span
                style="font-family:仿宋; font-size:14pt">：</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:28pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">1　</span><span
                style="font-family:仿宋; font-size:14pt">提前联系专家确认授课内容及授课时间提醒；</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:28pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">2　</span><span
                style="font-family:仿宋; font-size:14pt">收集专家授课信息资料；</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:28pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">3　</span><span
                style="font-family:仿宋; font-size:14pt">协助专家进行课件调整并进行作业及问题设置；</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:28pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">4　</span><span
                style="font-family:仿宋; font-size:14pt">协助技术对专家进行平台系统培训以及设备和网络设置；</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:28pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">5　</span><span style="font-family:仿宋; font-size:14pt">参训学员上课提醒。
            </span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21.25pt; orphans:0; text-align:justify; text-indent:-21.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">(2)</span><span style="font:7.0pt 'Times New Roman'">
            </span><span style="font-family:仿宋; font-size:14pt">在项目实施中，授课过程中助教要时刻为专家和项目组提供协助工作，具体表现为：</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:28pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">1　</span><span
                style="font-family:仿宋; font-size:14pt">授课过程中时刻监控课程状态并及时提醒汇报相关者（关注专家网络情况、课件播放情况、课程试听效果等）；</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:28pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">2　</span><span
                style="font-family:仿宋; font-size:14pt">提醒参训学员参与互动答题并反馈专家；</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:28pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">3　</span><span
                style="font-family:仿宋; font-size:14pt">协助专家解答学员问题；</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:28pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">4　</span><span
                style="font-family:仿宋; font-size:14pt">专家上课时间把控并提醒。</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21.25pt; orphans:0; text-align:justify; text-indent:-21.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">(3)</span><span style="font:7.0pt 'Times New Roman'">
            </span><span style="font-family:仿宋; font-size:14pt">项目实施后，授课过程中助教需要为专家和项目组的训后工作提供帮助支持，具体表现为：</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:28pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">1　</span><span
                style="font-family:仿宋; font-size:14pt">课程结束后协助项目组进行课程数据统计及简报撰写；</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:28pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">2　</span><span
                style="font-family:仿宋; font-size:14pt">提醒参训教师提交作业并对教师作业进行收集、整理；</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:28pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">3　</span><span
                style="font-family:仿宋; font-size:14pt">提醒专家对教师作业进行点评、并对优秀成果进行收集和整理；</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:28pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">4　</span><span
                style="font-family:仿宋; font-size:14pt">根据专家对参训教师提交作业的点评辅助项目组进行作业总结的撰写。</span></p>
        <p style="font-size:14pt; text-align: center;line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.011.png" width="560" height="322" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; text-indent:20pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">助教团队职责文件</span></p>
        <ol start="5" type="1" style="margin:0pt; padding-left:0pt">
            <li
                style="font-family:仿宋; font-size:14pt; font-weight:bold; line-height:150%; margin:0pt 0pt 10pt 19pt; orphans:0; padding-left:2.25pt; text-indent:0pt; widows:0">
                <span style="font-family:仿宋; font-size:14pt; font-weight:bold">平台及技术保障团队主要职责</span></li>
        </ol>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21.25pt; orphans:0; text-indent:-21.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">(1)</span><span style="font:7.0pt 'Times New Roman'">
            </span><span
                style="font-family:仿宋; font-size:14pt">训前对所有参训教师进行统一集体培训，保证所有学员能够熟练使用平台参与学习（对操作步骤和注意事项进行详细讲解，力争做到参训学员和授课教师当场就能听懂会用）；</span>
        </p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21.25pt; orphans:0; text-indent:-21.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">(2)</span><span style="font:7.0pt 'Times New Roman'">
            </span><span
                style="font-family:仿宋; font-size:14pt">提供详细的操作讲解文件，并下发至每一位学员，使学员在学习中遇到的平台使用问题能够随时找到答案（针对不同角色制作不同操作文件，以操作者角度详细讲解各功能操作，并提供不同格式的文件，方便参与者学习）；</span>
        </p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21.25pt; orphans:0; text-indent:-21.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">(3)</span><span style="font:7.0pt 'Times New Roman'">
            </span><span style="font-family:仿宋; font-size:14pt">创建技术保障群，随时提供支持和服务；</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21.25pt; orphans:0; text-indent:-21.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">(4)</span><span style="font:7.0pt 'Times New Roman'">
            </span><span style="font-family:仿宋; font-size:14pt">培训期间为各类活动提供保障支持。</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.012.png" width="400" height="356"
                alt="0f0fe1e3929ffa8af45761cf7bc6585"
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; text-indent:20pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">图：</span><span
                style="font-family:仿宋; font-size:14pt">技术团队职责文件</span></p>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc11274"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">（三）汇集资料，规范实施流程，保质高效</span></a></h2>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:24pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">为确保培训项目顺利运行，培训开展前项目组为项目运行中涉及到的每个环建立标准，规范流程。最后将内容整理成资料包，为项目的有序进行提供效率保障和质量支持，全面把控整个实施流程。</span>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.013.png" width="442" height="358" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.014.png" width="448" height="230" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.015.png" width="446" height="232" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><a
                name="_Toc19112"></a><a name="_Toc13646"></a><a name="_Toc18208"></a><a name="_Toc23748"><span
                    style="font-family:仿宋; font-size:14pt">图：</span><span
                    style="font-family:仿宋; font-size:14pt">流程标准和规范</span><span
                    style="-aw-bookmark-end:_Toc19112"></span><span style="-aw-bookmark-end:_Toc13646"></span><span
                    style="-aw-bookmark-end:_Toc18208"></span></a></p>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc15304886"></a><a name="_Toc13474944"></a><a name="_Toc12959"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">（四）手册</span><span
                    style="-aw-bookmark-end:_Toc15304886"></span><span
                    style="-aw-bookmark-end:_Toc13474944"></span><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">指导，操作演示，全面展示平台操作方法</span></a></h2>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:24pt; widows:0">
            <span
                style="font-family:仿宋; font-size:14pt">项目组在前期准备阶段根据项目特点以及不同角色的工作重点，针对不同角色制作操作手册以及注意事项，以操作者的角度详细阐述各功能的操作以及功能，每一操作步骤都配截图和文字说明图文并茂，帮助各角色巩固操作，并指派专业人员对本项目所有使用平台对象进行操作演示讲解力争做到参训学员和授课教师当场就能听懂会用，此外还录制操作微视频帮助参与者巩固掌握。针对个别未能完全掌握操作或未能参加前期操作培训的学员和专家进行单独沟通一对一指导，保证每一位项目参与者能操作，会操作，懂操作。</span>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.016.png" width="182" height="234" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" /><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.017.png" width="422" height="234" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.018.png" width="482" height="330" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.019.png" width="134" height="110" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" /><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.020.png" width="128" height="110" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" /><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.021.png" width="126" height="110" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：</span><span
                style="font-family:仿宋; font-size:14pt">操作手册、微视频及讲解演示</span></p>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc408"><span style="font-family:仿宋; font-size:14pt; font-weight:bold">（五）启动仪式，明确任务部署</span></a>
        </h2>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:24pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">为推进“名校联盟
                双师课堂”项目的有效开展与实施，项目每一阶段开始前项目组都召开启动仪式，仪式上对项目进行详细讲解，如</span><span
                style="font-family:仿宋; font-size:14pt">培养的模式、本阶段重点工作和目标、考评标准与具体措施等，帮助参训教师明确了任务与目标。</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:24pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:24pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:24pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:24pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
        <p style="font-size:14pt; text-align: center;line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.022.png" width="552" height="388" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：</span><span
                style="font-family:仿宋; font-size:14pt">启动仪式-明确部署</span></p>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc23604"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">（六）全程任务提醒，不错过任何学习</span></a></h2>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span
                style="font-family:仿宋; font-size:14pt">“我说的自己不自觉地会记住上课的时间也是因为项目组的细致安排和规划，每一次助教老师都提前提醒上课的时间，还在积极协调每一次授课教师和听课教师的问题，像一个行走的记事簿，总帮我们记得上课的时间，交作业的时间，让我们都不好意思偷懒了，嘿嘿，学习我们是认真的。”（宁夏银川市兴庆区第二十九小学-刘丹丹）</span>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.023.png" width="214" height="176" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" /><span
                style="font-family:仿宋; font-size:14pt"> </span><img src="./img/25761bad-f73c-436d-bc07-670cf37d7270.024.png"
                width="238" height="176" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.025.png" width="246" height="116" alt="IMG_9834"
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" /><span
                style="font-family:仿宋; font-size:14pt; font-weight:bold"> </span><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.026.png" width="290" height="116"
                alt="1764ebe223bf812c11c3301020b3351"
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：</span><span style="font-family:仿宋; font-size:14pt">学习提醒</span>
        </p>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt; font-weight:bold">（</span><span
                style="font-family:Arial; font-size:14pt; font-weight:bold">7</span><span
                style="font-family:仿宋; font-size:14pt; font-weight:bold">）</span><a name="_Toc26296"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">建立工作群组，实时跟进项目进展，问题立即解决</span></a></h2>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">考虑到微信群方便实时沟通，项目组建立了微信群，以保证及时有效的沟通。</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; text-indent:24pt; widows:0">
            <img src="./img/25761bad-f73c-436d-bc07-670cf37d7270.027.png" width="170" height="252" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" /><span
                style="font-family:仿宋; font-size:14pt"> </span><img src="./img/25761bad-f73c-436d-bc07-670cf37d7270.028.png"
                width="162" height="250" alt="37bb62f27d5069a2258ef6a68a001f2"
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; text-indent:24pt; widows:0">
            <img src="./img/25761bad-f73c-436d-bc07-670cf37d7270.029.png" width="170" height="252"
                alt="819e09f147bfe028955a9d5efb14c3f"
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" /><span
                style="font-family:仿宋; font-size:14pt"> </span><img src="./img/25761bad-f73c-436d-bc07-670cf37d7270.030.png"
                width="168" height="252" alt="acc54941cc46883dfaf35fc68fa621f"
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; text-indent:20pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">图：</span><span
                style="font-family:仿宋; font-size:14pt">微信交流群</span></p>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc25938"><span style="font-family:仿宋; font-size:14pt; font-weight:bold">（八）运行监督，定期汇报</span></a>
        </h2>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:24pt; widows:0">
            <span
                style="font-family:仿宋; font-size:14pt">正式学习阶段，项目组对项目的各个环节进行参与和把控，为每一次的直播做好保障，对学习中出现的问题随时进行解答，对学员学习情况实时监督提醒并定期进行整理汇报。</span>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.031.png" width="280" height="110" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" /><span
                style="font-family:仿宋; font-size:14pt"> </span><img src="./img/25761bad-f73c-436d-bc07-670cf37d7270.032.png"
                width="260" height="110" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.033.png" width="550" height="340" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：</span><span style="font-family:仿宋; font-size:14pt">工作汇报</span>
        </p>
        <h1
            style="font-size:14pt; line-height:241%; margin:17pt 0pt 16.5pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc1929"><span style="font-family:仿宋; font-size:14pt; font-weight:bold">四、项目完成情况</span><span
                    style="-aw-bookmark-end:_Toc10752"></span></a><a name="_Toc25105"></a></h1>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc7552"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">（一）严格执行标准，各学科课程圆满完成</span></a></h2>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span
                style="font-family:仿宋; font-size:14pt">在项目正式运行前，项目组制定了严格管理制度以及各环节的执行标准并且梳理了流程规范，在培训过程中按照“高标准，严要求”的宗旨来进行管理。</span><span
                style="font-family:仿宋; font-size:14pt">每一位授课专家在正式上课之前都会有助教对其进行操作培训和设备及网络检查；每节课的授课内容都会经过学科专家的把控，使得授课内容符合主题，贴合实际；每次课程都进行三次以上的课前学习提醒，课程开始都会安排学员进行签到，力争使每一位学员都不错过学习机会。</span>
        </p>
        <p style="font-size:14pt; text-align: center; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.034.png" width="546" height="288" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; text-align: center;line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.035.png" width="244" height="136"
                alt="d69035a4d7f5ec3818894c7db561059"
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" /><span
                style="font-family:仿宋; font-size:14pt"> </span><img src="./img/25761bad-f73c-436d-bc07-670cf37d7270.036.png"
                width="292" height="138" alt="9589102c9c3b9adadb9ab88a227dc3d"
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：</span><span style="font-family:仿宋; font-size:14pt">课程直播</span>
        </p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">2020年“名校联盟
                双师课堂”项目共计开课 <span class="font-weight" @click="handle(32)">112</span> 门次，上半年<span class="font-weight" @click="handle(30)">62</span>门次，其中：
								小学段开课<span class="font-weight" @click="handle(24)">22</span>门次（语文<span @click="handle(5)" class="font-weight">5</span>次、数学<span class="font-weight"@click="handle(3)" >5</span>次、英语<span class="font-weight" @click="handle(4)" >8</span>次、道法<span class="font-weight" @click="handle(2)" >4</span>次），
								中学段开课<span class="font-weight" @click="handle(25)">9</span>门次（语文<span class="font-weight" @click="handle(12)" >5</span>次、数学<span class="font-weight" @click="handle(10)" >5</span>次、英语<span class="font-weight" @click="handle(11)" >16</span>次、道法<span class="font-weight" @click="handle(9)" >5</span>次），中小学共同开课<span class="font-weight" @click="handle(26)" >4</span>次（全学科通识<span class="font-weight" @click="handle(7)" >5</span>次，中小学数学<span class="font-weight" @click="handle(8)" >2</span>次，中小学道法<span class="font-weight" @click="handle(6)" >2</span>次）；
								下半年<span class="font-weight" @click="handle(31)">50</span>门次，其中：小学段开课<span class="font-weight" @click="handle(27)">25</span>门次（语文<span class="font-weight" @click="handle(16)">6</span>次、数学<span class="font-weight" @click="handle(14)">5</span>次、英语<span class="font-weight" @click="handle(15)">8</span>次、道法<span class="font-weight" @click="handle(13)">6</span>次），中学段开课<span class="font-weight" @click="handle(28)">21</span>门次（语文<span class="font-weight" @click="handle(21)">6</span>次、数学<span class="font-weight" @click="handle(19)">6</span>次、英语<span class="font-weight" @click="handle(20)">4</span>次、道法<span class="font-weight" @click="handle(18)">5</span>次），
								中小学共同开课<span class="font-weight" @click="handle(29)">4</span>次（中小学数学<span class="font-weight" @click="handle(24)">2</span>次，中小学道法<span class="font-weight" @click="handle(17)">2</span>次）。为保障两个学段的参训教师在自己学段中的参训质量与效果，特此对每门学科的开设频率进行了总体把控。</span>
        </p>
        <div style="text-align:center">
            <table cellspacing="0" cellpadding="0"
                style="border-collapse:collapse; float:left; margin:0pt 9pt; width:459pt">
                <tr style="height:32.3pt">
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:21pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt; font-weight:bold">阶</span></p>
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt; font-weight:bold">段</span></p>
                    </td>
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:41.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt; font-weight:bold">月份</span></p>
                    </td>
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.5pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt; font-weight:bold">学段</span></p>
                    </td>
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt; font-weight:bold">学科</span></p>
                    </td>
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt; font-weight:bold">课程数量</span></p>
                    </td>
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:39.75pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt; font-weight:bold">当月课程总数</span></p>
                    </td>
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:24.75pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt; font-weight:bold">阶</span></p>
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt; font-weight:bold">段</span></p>
                    </td>
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:40.5pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt; font-weight:bold">月份</span></p>
                    </td>
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:51.75pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt; font-weight:bold">学段</span></p>
                    </td>
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt; font-weight:bold">学科</span></p>
                    </td>
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt; font-weight:bold">课程数量</span></p>
                    </td>
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt; font-weight:bold">当月课程总数</span></p>
                    </td>
                </tr>
                <tr style="height:32.3pt">
                    <td rowspan="35"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:21pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt; font-weight:bold">第</span><br /><span
                                style="font-family:仿宋; font-size:11pt; font-weight:bold">一</span><br /><span
                                style="font-family:仿宋; font-size:11pt; font-weight:bold">阶</span><br /><span
                                style="font-family:仿宋; font-size:11pt; font-weight:bold">段</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:41.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2020年</span><br /><span
                                style="font-family:仿宋; font-size:11pt">3月</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.5pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">小学/中学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">通识</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">4</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:39.75pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">4</span></p>
                    </td>
                    <td rowspan="35"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:24.75pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt; font-weight:bold">第</span><br /><span
                                style="font-family:仿宋; font-size:11pt; font-weight:bold">二</span><br /><span
                                style="font-family:仿宋; font-size:11pt; font-weight:bold">阶</span><br /><span
                                style="font-family:仿宋; font-size:11pt; font-weight:bold">段</span></p>
                    </td>
                    <td rowspan="8"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:40.5pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2020年</span><br /><span
                                style="font-family:仿宋; font-size:11pt">9月</span></p>
                    </td>
                    <td rowspan="3"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:51.75pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">初中</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">道法</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                    <td rowspan="8"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">13</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td rowspan="9"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:41.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2020年</span><br /><span
                                style="font-family:仿宋; font-size:11pt">4月</span></p>
                    </td>
                    <td rowspan="3"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.5pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">小学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">数学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                    <td rowspan="9"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:39.75pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">13</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">英语</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">英语</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">语文</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">语文</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                    <td rowspan="3"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:51.75pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">小学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">道法</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td rowspan="3"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.5pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">初中</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">数学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">英语</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">英语</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">3</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">语文</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">语文</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                    <td rowspan="2"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:51.75pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">小学/中学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">道法</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td rowspan="3"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.5pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">小学/中学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">道法</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">数学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">数学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                    <td rowspan="8"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:40.5pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2020年</span><br /><span
                                style="font-family:仿宋; font-size:11pt">10月</span></p>
                    </td>
                    <td rowspan="4"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:51.75pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">初中</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">道法</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                    <td rowspan="8"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">10</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">通识</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">数学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td rowspan="8"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:41.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2020年</span><br /><span
                                style="font-family:仿宋; font-size:11pt">5月</span></p>
                    </td>
                    <td rowspan="4"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.5pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">小学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">道法</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                    <td rowspan="8"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:39.75pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">16</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">英语</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">数学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">语文</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">英语</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                    <td rowspan="3"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:51.75pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">小学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">数学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">语文</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">英语</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td rowspan="4"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.5pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">初中</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">道法</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">语文</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">数学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:51.75pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">小学/中学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">道法</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">英语</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">5</span></p>
                    </td>
                    <td rowspan="8"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:40.5pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2020年</span><br /><span
                                style="font-family:仿宋; font-size:11pt">11月</span></p>
                    </td>
                    <td rowspan="4"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:51.75pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">初中</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">道法</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                    <td rowspan="8"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">13</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">语文</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">数学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td rowspan="9"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:41.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2020年</span><br /><span
                                style="font-family:仿宋; font-size:11pt">6月</span></p>
                    </td>
                    <td rowspan="4"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.5pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">小学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">道法</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                    <td rowspan="9"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:39.75pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">16</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">英语</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">数学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">语文</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">英语</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                    <td rowspan="4"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:51.75pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">小学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">道法</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">语文</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">数学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td rowspan="3"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.5pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">初中</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">数学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">英语</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">3</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">英语</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">6</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">语文</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">语文</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                    <td rowspan="8"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:40.5pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2020年</span><br /><span
                                style="font-family:仿宋; font-size:11pt">12月</span></p>
                    </td>
                    <td rowspan="4"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:51.75pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">初中</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">道法</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                    <td rowspan="8"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">12</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td rowspan="2"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.5pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">小学/中学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">数学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">数学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">3</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">道法</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">英语</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td rowspan="8"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:41.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2020年</span><br /><span
                                style="font-family:仿宋; font-size:11pt">7月</span></p>
                    </td>
                    <td rowspan="4"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.5pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">小学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">道法</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                    <td rowspan="8"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:39.75pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">13</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">语文</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">数学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                    <td rowspan="4"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:51.75pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">小学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">道法</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">英语</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">数学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">语文</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">英语</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td rowspan="4"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.5pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">初中</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">道法</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">语文</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                </tr>
                <tr style="height:16.8pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">数学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                    <td rowspan="3"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:40.5pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2021年</span><br /><span
                                style="font-family:仿宋; font-size:11pt">1月</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:51.75pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">初中</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">语文</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                    <td rowspan="3"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                </tr>
                <tr style="height:25.7pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">英语</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">2</span></p>
                    </td>
                    <td rowspan="2"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:51.75pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">小学</span></p>
                    </td>
                    <td rowspan="2"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:38.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">语文</span></p>
                    </td>
                    <td rowspan="2"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:32.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                </tr>
                <tr style="height:17.25pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:33pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">语文</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:30pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">1</span></p>
                    </td>
                </tr>
            </table>
        </div>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc8625"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">（二）多种互动方式，打破课堂沉默</span></a></h2>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">与普通在线课程不同的是，“名校联盟
                双师课堂”直播课程设计了全新的授课模式，课程中参训教师除了观看课程还能参与课堂互动，为了保证学习效果和学员的学习热情，专家在授课过程中设置了选择题、简答题、连麦等方式与学员进行多种形式的交流互动，通过每次互动的参与率判断学习者的学习状态。每节课还设置了测验环节，随学随练。</span>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.037.png" width="258" height="156" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" /><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.038.png" width="286" height="156" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" /><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.039.png" width="558" height="136" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：</span><span style="font-family:仿宋; font-size:14pt">答题互动</span>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.040.png" width="466" height="364" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：</span><span
                style="font-family:仿宋; font-size:14pt">连麦视频互动</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.041.png" width="162" height="306" alt="1611825577(1)"
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" /><span
                style="font-family:仿宋; font-size:14pt"> </span><img src="./img/25761bad-f73c-436d-bc07-670cf37d7270.042.png"
                width="160" height="306" alt="b222072bb0792ab01889e1fe7278fde"
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：</span><span
                style="font-family:仿宋; font-size:14pt">互动区</span><span
                style="font-family:仿宋; font-size:14pt">文字交流</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.043.png" width="372" height="240"
                alt="4d3f6370ef77c608a2d89afddd0c42d"
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" /><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.044.png" width="152" height="240"
                alt="2366868318e653bf00180590bfdec8a"
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：</span><span style="font-family:仿宋; font-size:14pt">课堂测验</span>
        </p>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc20240"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">（三）多种主题案例活动，促进理论向实践转化</span></a></h2>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:24pt; widows:0">
            <span
                style="font-family:仿宋; font-size:14pt">为了帮助参训教师更好的理解和掌握培训内容，能够将理论运用到日常教学实践，项目组联合首都师范大学、北京东城区史家小学集团，北京市东城区培新小学多次开展实践直播活动，通过实践案例、真实课堂、专家讲解等维度，帮助参训教师将培训中学习到的理论向实践转化。
            </span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.045.png" width="172" height="274"
                alt="eb3d672ee15c7f266789b79809142ad"
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" /><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.046.png" width="190" height="274" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" /><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.047.png" width="184" height="274"
                alt="7b5d1d83708d44f3a1b6a2efe428090"
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：</span><span style="font-family:仿宋; font-size:14pt">主题活动</span>
        </p>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc13457"><span style="font-family:仿宋; font-size:14pt; font-weight:bold">（</span><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">四</span><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">）培训收获，全面提升</span></a></h2>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span
                style="font-family:仿宋; font-size:14pt">经过一年学习的洗礼，学员们的收获不仅是学习课时的累加和作业数量的增长，更是得到了教学能力与专业技能的发展与提升。以下是来自参训教师的反馈：</span>
        </p>
        <h3
            style="font-size:14pt; line-height:172%; margin:13pt 0pt 13pt 21.25pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:-21.25pt; widows:0">
            <span style="font-family:Calibri; font-size:14pt; font-weight:bold">1.</span><span
                style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a
                name="_Toc10464"><span style="font-family:仿宋; font-size:14pt; font-weight:bold">学习与观念改变</span></a></h3>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span
                style="font-family:仿宋; font-size:14pt">任何人想发展都离不开书，读书的边界就是人生的边界。书籍可以让我们跨越时空与先哲进行精神对话。给我们培训的老师，几乎都谈到了这个问题。作为一名教师，我们不仅要读教育教学方面的书，还要博览群书。不读书，如何更加深入地教学，如何在课堂上与学生与文本进行对话?如何与时俱进地掌握教育教学的技巧？在培训中，我深感自己的差距与不足。作为一名教师，要不断的给自己“充电”。我深感只有不断的充实自己，博览群书，授课时才能做到游刃有余，而不是照本宣科、索然无味。教学需要教师不断刷新，而不是复制。</span><span
                style="font-family:仿宋; font-size:14pt; font-weight:bold">——银川市阅海第二小学</span><span
                style="font-family:仿宋; font-size:14pt; font-weight:bold">  </span><span
                style="font-family:仿宋; font-size:14pt; font-weight:bold">董晨</span></p>
        <h3
            style="font-size:14pt; line-height:172%; margin:13pt 0pt 13pt 21.25pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:-21.25pt; widows:0">
            <span style="font-family:Calibri; font-size:14pt; font-weight:bold">2.</span><span
                style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a
                name="_Toc21616"><span style="font-family:仿宋; font-size:14pt; font-weight:bold">课程与教材理解更加深刻</span></a>
        </h3>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span
                style="font-family:仿宋; font-size:14pt">通过对学科结构论的学习，给我今后的教学很多启发：教师要整体把握教材，沟通学科知识之间的联系，沟通书本世界和学生生活世界的联系，把教学的知识放在一个知识体系里，而不是孤立地学习，把知识串起来，形成知识链，知识树，形成一个知识网络。有结构的、有联系的知识学生就容易掌握。所以在今后的教学中要重视沟通数学知识本质之间的内在联系，使知识内容结构化。</span>
        </p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:right; text-indent:20.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt; font-weight:bold">——</span><span
                style="font-family:仿宋; font-size:14pt; font-weight:bold">银川市西夏区芦花洲小学</span><span
                style="font-family:仿宋; font-size:14pt; font-weight:bold">  </span><span
                style="font-family:仿宋; font-size:14pt; font-weight:bold">张丽</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span
                style="font-family:仿宋; font-size:14pt">小学阶段的数学知识对于学生而言，有着非常明显的抽象性，也就是学生在数学学习过程中仅仅的听老师讲解，而不进行独立思考将难以起到良好的学习作用，在实际教学工作中，为了让学生不会在课堂学习中产生过多的畏难情绪，进而继续影响学生对于独立思考的热情，教师在教学工作中就必须要对抽象的知识进行具象化，也就是通过各种教学手段让学生可以更好的理解教学工作中开展的内容。</span>
        </p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:right; text-indent:20.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt; font-weight:bold">——</span><span
                style="font-family:仿宋; font-size:14pt; font-weight:bold">银川市阅海第二小学</span><span
                style="font-family:仿宋; font-size:14pt; font-weight:bold">  </span><span
                style="font-family:仿宋; font-size:14pt; font-weight:bold">王春艳</span></p>
        <h3
            style="font-size:14pt; line-height:172%; margin:13pt 0pt 13pt 21.25pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:-21.25pt; widows:0">
            <span style="font-family:Calibri; font-size:14pt; font-weight:bold">3.</span><span
                style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a
                name="_Toc4985"><span style="font-family:仿宋; font-size:14pt; font-weight:bold">教学设计与实施能力提升</span></a>
        </h3>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">教学设计是教师教学工作的重要组成部分，教学设计能力是教师专业化的重要体现。在一些列</span><span
                style="font-family:仿宋; font-size:14pt">研修活动学习</span><span
                style="font-family:仿宋; font-size:14pt">与专家指导后，银川教师的教学设计正朝着科学、新颖、合理、</span><span
                style="font-family:仿宋; font-size:14pt">多种</span><span
                style="font-family:仿宋; font-size:14pt">信息化手段</span><span
                style="font-family:仿宋; font-size:14pt">融合</span><span
                style="font-family:仿宋; font-size:14pt">运用</span><span
                style="font-family:仿宋; font-size:14pt">、</span><span
                style="font-family:仿宋; font-size:14pt">多种评价方式</span><span
                style="font-family:仿宋; font-size:14pt">等方向进行良性转变。</span><span
                style="font-family:仿宋; font-size:14pt">以下为部分教师教学设计：</span></p>
        <p style="font-size:14pt; line-height:150%; margin:7.8pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt; font-weight:bold">Unit 6 Happy birthday! A Let’s learn
                教学设计</span></p>
        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:12pt">银川市金凤区植物园回民小学 腾丽佳</span></p>
        <p
            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:仿宋; font-size:12pt">1、</span><span
                style="font-family:仿宋; font-size:12pt">教材分析</span></p>
        <p
            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span
                style="font-family:仿宋; font-size:12pt">本节课的教学内容选自PEP小学英语三年级上册第六单元，这是一节单词新授课，主题是数字，与学生生活实际密切相关，语言知识点相对比较容易掌握。本节课是这个单元第二课时的内容，本课目标语言围绕5个数字one</span><span
                style="font-family:仿宋; font-size:12pt">，</span><span style="font-family:仿宋; font-size:12pt">
                two</span><span style="font-family:仿宋; font-size:12pt">，</span><span
                style="font-family:仿宋; font-size:12pt"> three</span><span
                style="font-family:仿宋; font-size:12pt">，</span><span style="font-family:仿宋; font-size:12pt">
                four</span><span style="font-family:仿宋; font-size:12pt">，</span><span
                style="font-family:仿宋; font-size:12pt"> five以及数量的问答，难点是在真实情景中的数字的正确使用。</span></p>
        <p
            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:仿宋; font-size:12pt">2、</span><span
                style="font-family:仿宋; font-size:12pt">学情分析</span></p>
        <p
            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span
                style="font-family:仿宋; font-size:12pt">本节课的教学对象是小学三年级的学生。年龄上，三年级学生活泼好动、思维活跃。知识积累上，对于三年级起点的孩子而言，语言积累量不多，在前三个月的学习过程中，学生掌握了简单的文具、颜色、动物和食物类单词，以及一些常用问候语。经过本单元第一课时的学习，学生已经能初步听说这几个单词，部分学生可以询问数量。</span>
        </p>
        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:仿宋; font-size:12pt">三、设计思路</span></p>
        <p
            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:12pt">结合该年龄段学生的心理和特点，本课采用了情景教学与小组竞争不断结合，总体的教学思路是充分利用</span><span
                style="font-family:仿宋; font-size:12pt">“</span><span
                style="font-family:仿宋; font-size:12pt">情景教学</span><span
                style="font-family:仿宋; font-size:12pt">”</span><span
                style="font-family:仿宋; font-size:12pt">这一重要方法扩展教学形式，培养、维持和发展学生学习英语的兴趣，提高英语教学的效果。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:仿宋; font-size:12pt">四、教学目标</span></p>
        <p
            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:12pt">1. 知识与技能： </span></p>
        <p
            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:12pt">（1）100%的学生能听说、认读新词one</span><span
                style="font-family:仿宋; font-size:12pt">，</span><span style="font-family:仿宋; font-size:12pt">
                two</span><span style="font-family:仿宋; font-size:12pt">，</span><span
                style="font-family:仿宋; font-size:12pt"> three</span><span
                style="font-family:仿宋; font-size:12pt">，</span><span style="font-family:仿宋; font-size:12pt">
                four</span><span style="font-family:仿宋; font-size:12pt">，</span><span
                style="font-family:仿宋; font-size:12pt"> five，并在</span><span
                style="font-family:仿宋; font-size:12pt">给Sam准备生日礼物的</span><span
                style="font-family:仿宋; font-size:12pt">语境</span><span
                style="font-family:仿宋; font-size:12pt">中正确运用这些单词表达数量。</span></p>
        <p
            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:12pt">（2）100%的学生能在</span><span
                style="font-family:仿宋; font-size:12pt">Sam生日派对的</span><span
                style="font-family:仿宋; font-size:12pt">情景中恰当、熟练地运用句型</span><span
                style="font-family:仿宋; font-size:12pt">“</span><span style="font-family:仿宋; font-size:12pt">How
                many?询问数量并正确回答。</span></p>
        <p
            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:12pt">2.过程与方法 </span></p>
        <p
            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:12pt">通过观察、体验、发现以及小组合作对话，能在</span><span
                style="font-family:仿宋; font-size:12pt">给Sam准备生日礼物的语言</span><span
                style="font-family:仿宋; font-size:12pt">情境中正确使用单词one</span><span
                style="font-family:仿宋; font-size:12pt">，</span><span style="font-family:仿宋; font-size:12pt">
                two</span><span style="font-family:仿宋; font-size:12pt">，</span><span
                style="font-family:仿宋; font-size:12pt"> three</span><span
                style="font-family:仿宋; font-size:12pt">，</span><span style="font-family:仿宋; font-size:12pt">
                four</span><span style="font-family:仿宋; font-size:12pt">，</span><span
                style="font-family:仿宋; font-size:12pt"> five和句型</span><span
                style="font-family:仿宋; font-size:12pt">“</span><span style="font-family:仿宋; font-size:12pt">How
                many?</span><span style="font-family:仿宋; font-size:12pt">”</span><span
                style="font-family:仿宋; font-size:12pt">询问数量并回答。</span></p>
        <p
            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:12pt">3.情感态度与价值观 </span></p>
        <p
            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:12pt; font-weight:normal">为Sam送去生日祝福；</span><span
                style="font-family:仿宋; font-size:12pt; font-weight:normal">留心生活中的数字。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:仿宋; font-size:12pt">五、教学重难点：</span></p>
        <p
            style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:12pt">能够正确使用</span><span
                style="font-family:仿宋; font-size:12pt">“</span><span style="font-family:仿宋; font-size:12pt">How
                many?</span><span style="font-family:仿宋; font-size:12pt">”</span><span
                style="font-family:仿宋; font-size:12pt">对数量进行问答。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:仿宋; font-size:12pt">六、教学准备</span></p>
        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:仿宋; font-size:12pt">1. 四线三格贴，单词卡片one</span><span
                style="font-family:仿宋; font-size:12pt">，</span><span style="font-family:仿宋; font-size:12pt">
                two</span><span style="font-family:仿宋; font-size:12pt">，</span><span
                style="font-family:仿宋; font-size:12pt"> three</span><span
                style="font-family:仿宋; font-size:12pt">，</span><span style="font-family:仿宋; font-size:12pt">
                four</span><span style="font-family:仿宋; font-size:12pt">，</span><span
                style="font-family:仿宋; font-size:12pt"> five</span></p>
        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:仿宋; font-size:12pt">2. 售货员头饰，奖品若干。</span></p>
        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:仿宋; font-size:12pt">3. 希沃课件，学习单</span></p>
        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:仿宋; font-size:12pt">七、教学过程</span></p>
        <div style="text-align:center">
            <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:0 auto; width:461.8pt">
                <tr>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:80.3pt">
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:仿宋; font-size:12pt; font-weight:bold">教学环节</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:158.65pt">
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:仿宋; font-size:12pt; font-weight:bold">教师活动</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:102.6pt">
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:仿宋; font-size:12pt; font-weight:bold">学生活动</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:76.3pt">
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:仿宋; font-size:12pt; font-weight:bold">设计意图</span></p>
                    </td>
                </tr>
                <tr>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:80.3pt">
                        <p style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
                                style="font-family:仿宋; font-size:12pt; font-weight:bold">Step I Warming up</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:158.65pt">
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">1. Greeting and grouping.</span><span
                                style="font-family:仿宋; font-size:12pt">（</span><span
                                style="font-family:仿宋; font-size:12pt">评价</span><span
                                style="font-family:仿宋; font-size:12pt">）</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">2. Let’s do.</span><span
                                style="font-family:仿宋; font-size:12pt">（</span><span
                                style="font-family:仿宋; font-size:12pt">激活颜色</span><span
                                style="font-family:仿宋; font-size:12pt">词汇。</span><span
                                style="font-family:仿宋; font-size:12pt">）</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">3. Let’s sing. </span><span
                                style="font-family:仿宋; font-size:12pt">（</span><span
                                style="font-family:仿宋; font-size:12pt">初步感知数字，揭示主题语境——生日</span><span
                                style="font-family:仿宋; font-size:12pt">）</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">“</span><span
                                style="font-family:仿宋; font-size:12pt">Ten little candles</span><span
                                style="font-family:仿宋; font-size:12pt">”</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Q: What’s the song about?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">4. Lead-in.</span><span
                                style="font-family:仿宋; font-size:12pt">（</span><span
                                style="font-family:仿宋; font-size:12pt">借用主情景图引入Sam的生日派对，复习词汇，引出</span><span
                                style="font-family:仿宋; font-size:12pt">“</span><span
                                style="font-family:仿宋; font-size:12pt">gifts</span><span
                                style="font-family:仿宋; font-size:12pt">”）</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Show a birthday cake.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Q: What’s that? </span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">   Whose birthday is it?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">   Who comes to the birthday party?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">What can you see in the party?</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:102.6pt">
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">1. Greeting.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">2. Let’s do.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">3. Sing the song and answer the
                                question.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">4. Look</span><span
                                style="font-family:仿宋; font-size:12pt">，</span><span
                                style="font-family:仿宋; font-size:12pt"> guess and say.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:76.3pt">
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">设置小组竞争激励学生。</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">课前热身</span><span
                                style="font-family:仿宋; font-size:12pt">，</span><span
                                style="font-family:仿宋; font-size:12pt">为学习新单词做铺垫。</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">用</span><span
                                style="font-family:仿宋; font-size:12pt">有趣的</span><span
                                style="font-family:仿宋; font-size:12pt">歌曲引出生日。</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">在情境中由cake激活学生的思维。</span></p>
                    </td>
                </tr>
                <tr>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:80.3pt">
                        <p style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
                                style="font-family:仿宋; font-size:12pt; font-weight:bold">Step II Presentation</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:158.65pt">
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">1. Let’s say.</span><span
                                style="font-family:仿宋; font-size:12pt"> （</span><span
                                style="font-family:仿宋; font-size:12pt">创设情境，呈现句型</span><span
                                style="font-family:仿宋; font-size:12pt">“</span><span
                                style="font-family:仿宋; font-size:12pt">How many gifts?</span><span
                                style="font-family:仿宋; font-size:12pt">”</span><span
                                style="font-family:仿宋; font-size:12pt">）</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">So many gifts!</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:7pt; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Q: What color is it?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt"> Q:How many gifts? </span><span
                                style="font-family:仿宋; font-size:12pt">（</span><span
                                style="font-family:仿宋; font-size:12pt">板书句型</span><span
                                style="font-family:仿宋; font-size:12pt">）</span><span
                                style="font-family:仿宋; font-size:12pt"> Let’s count. </span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Which one do you want to open?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">2.Learn new words.</span><span
                                style="font-family:仿宋; font-size:12pt"> （</span><span
                                style="font-family:仿宋; font-size:12pt">在单词学习环节，引导学生主动猜一猜礼盒里</span><span
                                style="font-family:仿宋; font-size:12pt">物品的数量。）</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">（</span><span
                                style="font-family:仿宋; font-size:12pt">1</span><span
                                style="font-family:仿宋; font-size:12pt">）</span><span
                                style="font-family:仿宋; font-size:12pt">Learn </span><span
                                style="font-family:仿宋; font-size:12pt">“</span><span
                                style="font-family:仿宋; font-size:12pt">one</span><span
                                style="font-family:仿宋; font-size:12pt">”</span><span
                                style="font-family:仿宋; font-size:12pt">.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Q: What’s that?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">How many bears? Guess!</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Listen and repeat.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Let’s write together!</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Chant and show.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">（</span><span
                                style="font-family:仿宋; font-size:12pt">2</span><span
                                style="font-family:仿宋; font-size:12pt">）</span><span
                                style="font-family:仿宋; font-size:12pt">Learn </span><span
                                style="font-family:仿宋; font-size:12pt">“</span><span
                                style="font-family:仿宋; font-size:12pt">two</span><span
                                style="font-family:仿宋; font-size:12pt">”</span><span
                                style="font-family:仿宋; font-size:12pt"> .</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Guess: What’s that?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Can you ask like: How many …? </span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Listen and repeat.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Can you read this word?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">What can you find?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Let’s write together!</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Chant and show.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">（</span><span
                                style="font-family:仿宋; font-size:12pt">3</span><span
                                style="font-family:仿宋; font-size:12pt">）</span><span
                                style="font-family:仿宋; font-size:12pt">Learn </span><span
                                style="font-family:仿宋; font-size:12pt">“</span><span
                                style="font-family:仿宋; font-size:12pt">three</span><span
                                style="font-family:仿宋; font-size:12pt">”</span><span
                                style="font-family:仿宋; font-size:12pt">.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Guess: What’s that?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Can you ask like: How many …? </span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Can you read?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">强调th的发音。</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Listen and repeat.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Chant and show.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Let’s write together!</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">（</span><span
                                style="font-family:仿宋; font-size:12pt">4</span><span
                                style="font-family:仿宋; font-size:12pt">）</span><span
                                style="font-family:仿宋; font-size:12pt">Learn </span><span
                                style="font-family:仿宋; font-size:12pt">“</span><span
                                style="font-family:仿宋; font-size:12pt">four</span><span
                                style="font-family:仿宋; font-size:12pt">”</span><span
                                style="font-family:仿宋; font-size:12pt">.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Guess: What’s that?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">How many birds? Guess.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">your – four   强调our的发音。</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Listen and repeat.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Read in pairs.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Chant and show. Can you show </span><span
                                style="font-family:仿宋; font-size:12pt">“</span><span
                                style="font-family:仿宋; font-size:12pt">four</span><span
                                style="font-family:仿宋; font-size:12pt">”</span><span
                                style="font-family:仿宋; font-size:12pt"> for us?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Let’s write together!</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">（</span><span
                                style="font-family:仿宋; font-size:12pt">5</span><span
                                style="font-family:仿宋; font-size:12pt">）</span><span
                                style="font-family:仿宋; font-size:12pt">Learn </span><span
                                style="font-family:仿宋; font-size:12pt">“</span><span
                                style="font-family:仿宋; font-size:12pt">five</span><span
                                style="font-family:仿宋; font-size:12pt">”</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Guess: What’s that?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Can you ask like: How many …? </span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Can you read?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">强调i-e的发音。</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Listen and repeat.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Clap hands and say </span><span
                                style="font-family:仿宋; font-size:12pt">“</span><span
                                style="font-family:仿宋; font-size:12pt">five</span><span
                                style="font-family:仿宋; font-size:12pt">”</span><span
                                style="font-family:仿宋; font-size:12pt">.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Chant and show.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Let’s write together!</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:102.6pt">
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">1.Learn and say: How many gifts?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">The … one.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">2. Learn the new words.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">（</span><span
                                style="font-family:仿宋; font-size:12pt">1</span><span
                                style="font-family:仿宋; font-size:12pt">）</span><span
                                style="font-family:仿宋; font-size:12pt">Learn </span><span
                                style="font-family:仿宋; font-size:12pt">“</span><span
                                style="font-family:仿宋; font-size:12pt">one</span><span
                                style="font-family:仿宋; font-size:12pt">”</span><span
                                style="font-family:仿宋; font-size:12pt">.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Guess.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Listen and repeat.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Spell and write.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Show and chant.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">（</span><span
                                style="font-family:仿宋; font-size:12pt">2</span><span
                                style="font-family:仿宋; font-size:12pt">）</span><span
                                style="font-family:仿宋; font-size:12pt">Learn </span><span
                                style="font-family:仿宋; font-size:12pt">“</span><span
                                style="font-family:仿宋; font-size:12pt">two</span><span
                                style="font-family:仿宋; font-size:12pt">”</span><span
                                style="font-family:仿宋; font-size:12pt"> .</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Guess.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Try to ask: How many …?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Listen and repeat.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Read and find.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Spell and write.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Read and touch the word.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Show and chant.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">（</span><span
                                style="font-family:仿宋; font-size:12pt">3</span><span
                                style="font-family:仿宋; font-size:12pt">）</span><span
                                style="font-family:仿宋; font-size:12pt">Learn </span><span
                                style="font-family:仿宋; font-size:12pt">“</span><span
                                style="font-family:仿宋; font-size:12pt">three</span><span
                                style="font-family:仿宋; font-size:12pt">”</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Guess.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Try to ask: How many …?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Look and read.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Listen and repeat.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Chant and show.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Spell and write.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">（</span><span
                                style="font-family:仿宋; font-size:12pt">4</span><span
                                style="font-family:仿宋; font-size:12pt">）</span><span
                                style="font-family:仿宋; font-size:12pt"> Learn </span><span
                                style="font-family:仿宋; font-size:12pt">“</span><span
                                style="font-family:仿宋; font-size:12pt">four</span><span
                                style="font-family:仿宋; font-size:12pt">”</span><span
                                style="font-family:仿宋; font-size:12pt">.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Guess the numbers.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Read and find.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Listen and repeat.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Chant and show.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Spell and write.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">（</span><span
                                style="font-family:仿宋; font-size:12pt">5</span><span
                                style="font-family:仿宋; font-size:12pt">）</span><span
                                style="font-family:仿宋; font-size:12pt">Learn </span><span
                                style="font-family:仿宋; font-size:12pt">“</span><span
                                style="font-family:仿宋; font-size:12pt">five</span><span
                                style="font-family:仿宋; font-size:12pt">”</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Guess.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Ask: How many apples? </span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Can you read?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Listen and repeat.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Clap hands and say.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Chant and show.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Let’s write together!</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:76.3pt">
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">引导</span><span
                                style="font-family:仿宋; font-size:12pt">学生根据自己的喜好打开不同颜色的礼盒，激发学生探索欲</span><span
                                style="font-family:仿宋; font-size:12pt">和主动性</span><span
                                style="font-family:仿宋; font-size:12pt">。</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">先读一读，再发现</span><span
                                style="font-family:仿宋; font-size:12pt">“</span><span
                                style="font-family:仿宋; font-size:12pt">two</span><span
                                style="font-family:仿宋; font-size:12pt">”</span><span
                                style="font-family:仿宋; font-size:12pt">和</span><span
                                style="font-family:仿宋; font-size:12pt">“</span><span
                                style="font-family:仿宋; font-size:12pt">too</span><span
                                style="font-family:仿宋; font-size:12pt">”</span><span
                                style="font-family:仿宋; font-size:12pt">同音不同形，培养学生的观察能力和归纳总结的能力。</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">引导学生尝试拆音、拼读单词，进行单词发音学法的指导，然后再正音。</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">设置不同形式</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">的活动练习单词的发音，增加趣味性，激发学生的兴趣。</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">看口型，体会</span><span
                                style="font-family:仿宋; font-size:12pt">“</span><span
                                style="font-family:仿宋; font-size:12pt">th</span><span
                                style="font-family:仿宋; font-size:12pt">”“</span><span
                                style="font-family:仿宋; font-size:12pt">i-e</span><span
                                style="font-family:仿宋; font-size:12pt">”</span><span
                                style="font-family:仿宋; font-size:12pt">发音的特点，培养学生观察和倾听的能力，有助于正确发音，解决发音难点。</span></p>
                    </td>
                </tr>
                <tr>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:80.3pt">
                        <p style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
                                style="font-family:仿宋; font-size:12pt; font-weight:bold">Step III Practice and
                                consolidation</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:158.65pt">
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">1. Bomb.</span><span
                                style="font-family:仿宋; font-size:12pt">（</span><span
                                style="font-family:仿宋; font-size:12pt">炸弹游戏</span><span
                                style="font-family:仿宋; font-size:12pt">，</span><span
                                style="font-family:仿宋; font-size:12pt">巩固的单词的</span><span
                                style="font-family:仿宋; font-size:12pt">音形义</span><span
                                style="font-family:仿宋; font-size:12pt">。）</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">2. </span><span
                                style="font-family:仿宋; font-size:12pt">Quick response</span><span
                                style="font-family:仿宋; font-size:12pt">.</span><span
                                style="font-family:仿宋; font-size:12pt">（</span><span
                                style="font-family:仿宋; font-size:12pt">看图，快速说出数量。</span><span
                                style="font-family:仿宋; font-size:12pt">）</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Q: How many ... can you see?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">3. Ask and count.</span><span
                                style="font-family:仿宋; font-size:12pt">（</span><span
                                style="font-family:仿宋; font-size:12pt">在</span><span
                                style="font-family:仿宋; font-size:12pt">Sam生日派对的</span><span
                                style="font-family:仿宋; font-size:12pt">情景中</span><span
                                style="font-family:仿宋; font-size:12pt">是用所学词汇和句型问答数量。</span><span
                                style="font-family:仿宋; font-size:12pt">）</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Sam gets many gifts. </span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Q: What can you see?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:14pt; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">How many cards?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:14pt; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">How many balloons?</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Ask and answer with your partner.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:14pt; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">-- How many …? </span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:14pt; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">-- ... </span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Wow! So many gifts!</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Do you want to go to Sam’s birthday
                                party?</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:102.6pt">
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">1. Play the game. </span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <ol start="2" type="1" style="margin:0pt; padding-left:0pt">
                            <li
                                style="font-family:仿宋; font-size:12pt; line-height:120%; list-style-position:inside; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:0pt; widows:0">
                                <span style="font:7.0pt 'Times New Roman'"> </span><span
                                    style="font-family:仿宋; font-size:12pt">Look and say as quickly as possible.</span>
                            </li>
                        </ol>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">3. Look and say.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Ask and answer in pairs.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:76.3pt">
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">游戏抓住学生的注意力，增加趣味性的同时，提高学生的认读能力。</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">看图快速说出数量，锻炼学生的英语思维能力。</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">小队子对话，询问数量并回答，巩固单词和句型，培养用英语对话的能力。</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                </tr>
                <tr>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:80.3pt">
                        <p style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
                                style="font-family:仿宋; font-size:12pt; font-weight:bold">Step IV Extension</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:158.65pt">
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">1. Talk and show</span><span
                                style="font-family:仿宋; font-size:12pt">. （</span><span
                                style="font-family:仿宋; font-size:12pt">创设</span><span
                                style="font-family:仿宋; font-size:12pt">在商店给Sam准备生日礼物的情境，两人合作进行角色扮演，</span><span
                                style="font-family:仿宋; font-size:12pt">正确运用这些单词表达数量。</span><span
                                style="font-family:仿宋; font-size:12pt">）</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Q: If you can go</span><span
                                style="font-family:仿宋; font-size:12pt">，</span><span
                                style="font-family:仿宋; font-size:12pt"> what gifts can you prepare? </span></p>
                        <p
                            style="font-size:14pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Do you know other …?</span><span
                                style="font-family:仿宋; font-size:14pt"> </span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Let's prepare some gifts for Sam. </span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Act in pairs and show!</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">2. Go to Sam’s
                                home.（找到Sam家的住址，读一读：楼号、单元号、楼层、门牌号。）</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <ol start="3" type="1" style="margin:0pt; padding-left:0pt">
                            <li
                                style="font-family:仿宋; font-size:12pt; line-height:120%; list-style-position:inside; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:0pt; widows:0">
                                <span style="font:7.0pt 'Times New Roman'"> </span><span
                                    style="font-family:仿宋; font-size:12pt">Say </span><span
                                    style="font-family:仿宋; font-size:12pt">“</span><span
                                    style="font-family:仿宋; font-size:12pt">Happy birthday</span><span
                                    style="font-family:仿宋; font-size:12pt">”</span><span
                                    style="font-family:仿宋; font-size:12pt"> to Sam!</span><span
                                    style="font-family:仿宋; font-size:12pt">（</span><span
                                    style="font-family:仿宋; font-size:12pt">唱生日快乐歌，为Sam 送去生日祝福。</span><span
                                    style="font-family:仿宋; font-size:12pt">）</span></li>
                        </ol>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:102.6pt">
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <ol type="1" style="margin:0pt; padding-left:0pt">
                            <li
                                style="font-family:仿宋; font-size:12pt; line-height:120%; list-style-position:inside; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:0pt; widows:0">
                                <span style="font:7.0pt 'Times New Roman'"> </span><span
                                    style="font-family:仿宋; font-size:12pt">Say out various words.</span></li>
                        </ol>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Work in pairs and make a dialogue.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">A:  </span><span
                                style="font-family:仿宋; font-size:12pt; text-decoration:underline">     </span><span
                                style="font-family:仿宋; font-size:12pt; text-decoration:underline"> </span><span
                                style="font-family:仿宋; font-size:12pt; text-decoration:none">， </span><span
                                style="font-family:仿宋; font-size:12pt">please.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">B:</span><span
                                style="font-family:仿宋; font-size:12pt"> </span><span
                                style="font-family:仿宋; font-size:12pt">How</span><span
                                style="font-family:仿宋; font-size:12pt"> </span><span
                                style="font-family:仿宋; font-size:12pt">many?        A:  </span><span
                                style="font-family:仿宋; font-size:12pt; text-decoration:underline">      </span><span
                                style="font-family:仿宋; font-size:12pt">.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">B: Here you are.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">A: Thank you.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">B: You're welcome.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">2.Read the numbers.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">3.</span><span
                                style="font-family:仿宋; font-size:12pt">Say </span><span
                                style="font-family:仿宋; font-size:12pt">“</span><span
                                style="font-family:仿宋; font-size:12pt">Happy birthday</span><span
                                style="font-family:仿宋; font-size:12pt">”</span><span
                                style="font-family:仿宋; font-size:12pt"> to Sam!</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:76.3pt">
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">激活学生思维，为</span><span
                                style="font-family:仿宋; font-size:12pt">购买礼物</span><span
                                style="font-family:仿宋; font-size:12pt">环节做铺垫。</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span
                                style="font-family:仿宋; font-size:12pt">综合运用相关词汇和句型，进行语言交际和输出，培养学生的合作能力和语言表达能力，体验成就感，增强自信心。</span>
                        </p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">观察</span><span
                                style="font-family:仿宋; font-size:12pt">去Sam家的途中遇到的住址相关的数字</span><span
                                style="font-family:仿宋; font-size:12pt">，</span><span
                                style="font-family:仿宋; font-size:12pt">读一读，体会数字的趣味。</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">情感渗透。</span></p>
                    </td>
                </tr>
                <tr>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:80.3pt">
                        <p style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
                                style="font-family:仿宋; font-size:12pt; font-weight:bold">Step V Summary and
                                homework</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:158.65pt">
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">1. Summary.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:14pt; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Let’s sing.</span><span
                                style="font-family:仿宋; font-size:12pt">（</span><span
                                style="font-family:仿宋; font-size:12pt">总结复习</span><span
                                style="font-family:仿宋; font-size:12pt">）</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">2. Homework</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">（</span><span
                                style="font-family:仿宋; font-size:12pt">1</span><span
                                style="font-family:仿宋; font-size:12pt">）</span><span
                                style="font-family:仿宋; font-size:12pt"> Count from 1 to 5 for your parents.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">（</span><span
                                style="font-family:仿宋; font-size:12pt">2</span><span
                                style="font-family:仿宋; font-size:12pt">）</span><span
                                style="font-family:仿宋; font-size:12pt"> Find numbers in your life and read them. </span>
                        </p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">3. Evaluation</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">Which group wins? Let’s count
                                together!</span><span style="font-family:仿宋; font-size:12pt">（</span><span
                                style="font-family:仿宋; font-size:12pt">数一数得到的礼物数量。</span><span
                                style="font-family:仿宋; font-size:12pt">）</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:102.6pt">
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">1.Summary.</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <ol start="2" type="1" style="margin:0pt; padding-left:0pt">
                            <li
                                style="font-family:仿宋; font-size:12pt; line-height:120%; list-style-position:inside; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:0pt; widows:0">
                                <span style="font:7.0pt 'Times New Roman'"> </span><span
                                    style="font-family:仿宋; font-size:12pt">Finish homework</span></li>
                        </ol>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <ol start="3" type="1" style="margin:0pt; padding-left:0pt">
                            <li
                                style="font-family:仿宋; font-size:12pt; line-height:120%; list-style-position:inside; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:0pt; widows:0">
                                <span style="font:7.0pt 'Times New Roman'"> </span><span
                                    style="font-family:仿宋; font-size:12pt">Count and get gifts.</span></li>
                        </ol>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:76.3pt">
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">齐唱歌曲，复习本课重点知识，加深记忆，内化知识。</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt">运用本课所学知识，数一数，比一比，呼应主题。</span></p>
                    </td>
                </tr>
                <tr>
                    <td colspan="4"
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:450.25pt">
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:仿宋; font-size:12pt; font-weight:bold">板书设计</span></p>
                        <p
                            style="font-size:12pt; line-height:120%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                            <img src="./img/25761bad-f73c-436d-bc07-670cf37d7270.048.png" width="167" height="220" alt=""
                                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" /><span
                                style="font-family:仿宋; font-size:12pt; font-weight:bold">        </span><img
                                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.049.png" width="165" height="93" alt=""
                                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                        </p>
                    </td>
                </tr>
                <tr style="height:0pt">
                    <td style="width:91.1pt; border:none"></td>
                    <td style="width:169.45pt; border:none"></td>
                    <td style="width:113.4pt; border:none"></td>
                    <td style="width:87.1pt; border:none"></td>
                </tr>
            </table>
        </div>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:28pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">这一年中大量的理论学习就像是一场头脑风暴，更新了很多的理念，使我产生了太多的思考。带着这些理论上的引领和方法上的指导，本学期我和我们教研组进行了大胆的“多边形面积”单元整体设计，虽然只是初步的探究，仅仅是对平行四边形面积、三角形面积和梯形面积进行了大胆和创新的设计，但是这些设计是我们基于单元整体备课前提下进行的。大胆的尝试也让我们对“单元整体备课”有了自己的理解。平行四边形面积一课的设计，在王教授的精心指导下，我打破了这节传统课多年来的设计模式，从学生已有的经验出发，让学生在已有经验的基础上大胆地猜想、验证、质疑、再验证、最后获得结论，课堂设计的思路清晰，结构完整。最重要的是学生很享受这节课的学习过程，享受操作和探究的过程、享受质疑和追问的过程、享受答疑解惑后的成就感。</span>
        </p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:right; text-indent:21pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt; font-weight:bold">——</span><span
                style="font-family:仿宋; font-size:14pt; font-weight:bold">银川市实验小学</span><span
                style="font-family:仿宋; font-size:14pt; font-weight:bold">  </span><span
                style="font-family:仿宋; font-size:14pt; font-weight:bold">董艳萍</span></p>
        <h3
            style="font-size:14pt; line-height:172%; margin:13pt 0pt 13pt 21.25pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:-21.25pt; widows:0">
            <span style="font-family:Calibri; font-size:14pt; font-weight:bold">4.</span><span
                style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a
                name="_Toc16292"><span style="font-family:仿宋; font-size:14pt; font-weight:bold">教学研究与教师成长</span></a>
        </h3>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span
                style="font-family:仿宋; font-size:14pt">作为一名青年教师，总觉得做研究离我很遥远，在思想上没有足够的认识。这段时间，在各类频繁的教学研讨交流活动中，我逐渐了解到基础教育课程改革的背景和意义，意识到教育研究的重要和普遍，认识到只有每一位一线教师都成为研究型教师，才能实质推动教育的改革与创新。教育研究好像并没有我所想的那么难，就像王彦伟老师说的，做研究要“小”、“真”、“新”、“实”，从这个角度来看，我们一线教师拥有做研究的很有利的条件，我们首先要努力去做的，就是认真对待自己每节课的教学，有意识的去发现课堂教学和学生学习过程中出现的问题，然后用心琢磨、仔细研究去解决。</span>
        </p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:right; text-indent:20.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt; font-weight:bold">——</span><span
                style="font-family:仿宋; font-size:14pt; font-weight:bold">银川市实验小学</span><span
                style="font-family:仿宋; font-size:14pt; font-weight:bold">  </span><span
                style="font-family:仿宋; font-size:14pt; font-weight:bold">马璐瑶</span></p>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc13315"><span style="font-family:仿宋; font-size:14pt; font-weight:bold">（</span><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">五</span><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">）种子教师培养，人才储备</span></a></h2>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span
                style="font-family:仿宋; font-size:14pt">项目第一阶段于2020年上半年正式启动，在规划初期便设立了“种子教师”培养机制，通过项目实施，对新的教育理念及模式有积极学习意愿且综合素质较高的参训教师脱颖而出，采取专家一对一的形式进行“种子教师”培养，成为
                “双师课堂”项目为银川基础教育储备的人才。</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:24pt; widows:0">
            <span
                style="font-family:仿宋; font-size:14pt">种子教师培养过程中，不仅关注培养对象个人的成长，同时更加注重种子教师的示范带头与辐射引领作用。因此除了教学成果外，项目组还要求每位种子教师思考并完成个人发展规划。在规划个人专业发展方向以及研修路径的同时，还要具备牵头引领的意识，认真思考今后工作中如何发挥示范辐射作用，将个人成长收获作用于学校教学改革，带动学校、区域整体教育教学能力的提升。让种子教师凝结的教育智慧，在促进当地教育教学改革过程中生根发芽，在助推银川市基础教育质量提升浪潮中落地开花。</span>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
        <div style="text-align:center">
            <table cellspacing="0" cellpadding="0"
                style="border-collapse:collapse; float:left; margin:0pt 9pt; width:415.85pt">
                <tr style="height:19.85pt">
                    <td colspan="6"
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.12pt; padding-right:0.12pt; padding-top:0.12pt; vertical-align:middle; width:414.1pt">
                        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:12pt; font-weight:bold">各学科种子教师名单</span></p>
                    </td>
                </tr>
                <tr style="height:19.85pt">
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.12pt; padding-right:0.12pt; padding-top:0.12pt; vertical-align:middle; width:47.6pt">
                        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:12pt; font-weight:bold">学科</span></p>
                    </td>
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.12pt; padding-right:0.12pt; padding-top:0.12pt; vertical-align:middle; width:48.25pt">
                        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:12pt; font-weight:bold">姓名</span></p>
                    </td>
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.12pt; padding-right:0.12pt; padding-top:0.12pt; vertical-align:middle; width:112.8pt">
                        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:12pt; font-weight:bold">工作单位</span></p>
                    </td>
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; vertical-align:middle; width:47.3pt">
                        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:12pt; font-weight:bold">学科</span></p>
                    </td>
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; vertical-align:middle; width:42.9pt">
                        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:12pt; font-weight:bold">姓名</span></p>
                    </td>
                    <td
                        style="background-color:#d7d7d7; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; vertical-align:middle; width:111pt">
                        <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:12pt; font-weight:bold">工作单位</span></p>
                    </td>
                </tr>
                <tr style="height:17pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.12pt; padding-right:0.12pt; padding-top:0.12pt; vertical-align:middle; width:47.6pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">小学语文</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.12pt; padding-right:0.12pt; padding-top:0.12pt; vertical-align:middle; width:48.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">纪光娓</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.12pt; padding-right:0.12pt; padding-top:0.12pt; vertical-align:middle; width:112.8pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">银川市实验小学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; vertical-align:middle; width:47.3pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">初中语文</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; vertical-align:middle; width:42.9pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">韩宁 </span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; vertical-align:middle; width:111pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">　贺兰县第三中学 </span></p>
                    </td>
                </tr>
                <tr style="height:17pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.12pt; padding-right:0.12pt; padding-top:0.12pt; vertical-align:middle; width:47.6pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">小学数学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.12pt; padding-right:0.12pt; padding-top:0.12pt; vertical-align:middle; width:48.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">董艳萍</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.12pt; padding-right:0.12pt; padding-top:0.12pt; vertical-align:middle; width:112.8pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">银川市实验小学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; vertical-align:middle; width:47.3pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">初中数学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; vertical-align:middle; width:42.9pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">刘萍</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; vertical-align:middle; width:111pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">银川市第二十五中学</span></p>
                    </td>
                </tr>
                <tr style="height:17pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.12pt; padding-right:0.12pt; padding-top:0.12pt; vertical-align:middle; width:47.6pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">小学英语</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.12pt; padding-right:0.12pt; padding-top:0.12pt; vertical-align:middle; width:48.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">滕丽佳</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.12pt; padding-right:0.12pt; padding-top:0.12pt; vertical-align:middle; width:112.8pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">金凤区植物园回民小学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; vertical-align:middle; width:47.3pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">初中英语</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; vertical-align:middle; width:42.9pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">仲萱</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; vertical-align:middle; width:111pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">滨河新区景城第一中学</span></p>
                    </td>
                </tr>
                <tr style="height:17pt">
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.12pt; padding-right:0.12pt; padding-top:0.12pt; vertical-align:middle; width:47.6pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">小学道法</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.12pt; padding-right:0.12pt; padding-top:0.12pt; vertical-align:middle; width:48.25pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">伏媛</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.12pt; padding-right:0.12pt; padding-top:0.12pt; vertical-align:middle; width:112.8pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">银川市永泰小学</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; vertical-align:middle; width:47.3pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">初中道法</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; vertical-align:middle; width:42.9pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">王慧</span></p>
                    </td>
                    <td
                        style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; vertical-align:middle; width:111pt">
                        <p style="font-size:11pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center"><span
                                style="font-family:仿宋; font-size:11pt">灵武市回民中学</span></p>
                    </td>
                </tr>
                <tr style="height:0pt">
                    <td style="width:48.6pt; border:none"></td>
                    <td style="width:49.25pt; border:none"></td>
                    <td style="width:113.8pt; border:none"></td>
                    <td style="width:48.05pt; border:none"></td>
                    <td style="width:43.65pt; border:none"></td>
                    <td style="width:111.75pt; border:none"></td>
                </tr>
            </table>
        </div>
        <p
            style="font-size:14pt; line-height:150%; margin:7.8pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span
                style="font-family:仿宋; font-size:14pt">各学科种子教师一对一在线指导过程中，学科指导专家从种子教师在教学过程中遇到的困惑、专业发展上需求与目标或是学科中需要完成的教学任务为出发点，确定针对性的培养目标，制定个性化的培养方案，或采用任务驱动、或借助课堂任务为载体，明确种子教师培养预期目标与展示成果。</span>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.050.png" width="530" height="346"
                alt="aa9bf8789147bf066128feae824f07e"
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：</span><span
                style="font-family:仿宋; font-size:14pt">种子教师个人发展规划</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; text-indent:10pt; widows:0">
            <img src="./img/25761bad-f73c-436d-bc07-670cf37d7270.051.png" width="234" height="132" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" /><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.052.png" width="236" height="132" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" /><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.053.png" width="212" height="132" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" /><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.054.png" width="234" height="132" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：</span><span
                style="font-family:仿宋; font-size:14pt">种子教师课堂实录</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.055.png" width="556" height="142" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" /><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.056.png" width="556" height="186" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：</span><span
                style="font-family:仿宋; font-size:14pt">种子教师课程设计与课件</span></p>
        <h1
            style="font-size:14pt; line-height:241%; margin:17pt 0pt 16.5pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc3638"><span style="font-family:仿宋; font-size:14pt; font-weight:bold">五、项目效益</span><span
                    style="-aw-bookmark-end:_Toc25105"></span></a></h1>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span
                style="font-family:仿宋; font-size:14pt">本次培训在京银两地的共同支持与领导下，在参训老师和项目组共同的努力下，37所项目校的450参训教师圆满完成了学习任务，为本次培训交出了一份令人满意的答卷。</span>
        </p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:24pt; widows:0">
            <span style="color:#ff0000; font-family:仿宋; font-size:14pt">&#xa0;</span></p>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc13274"></a><a name="_Toc26128051"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">（一）教育效能</span><span
                    style="-aw-bookmark-end:_Toc13274"></span></a></h2>
        <h3
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:Calibri; font-size:14pt; font-weight:bold">1.</span><span
                style="font:7.0pt 'Times New Roman'">&#xa0; </span><a name="_Toc26128052"></a><a name="_Toc22438"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">教育理念创新</span><span
                    style="-aw-bookmark-end:_Toc26128052"></span></a></h3>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">“名校联盟 双师课堂”项目的运行，将运用人工智能等新技术引入教学手段，推动了银川当地“人工智能</span><span
                style="font-family:Calibri; font-size:14pt">+</span><span
                style="font-family:仿宋; font-size:14pt">教育”的创新融合，使智能教学系统的建设和应用得到了有效实践。通过在线学习新模式的探索与智慧设备的使用，引领参训教师进一步理解人工智能是促进包括教育在内的社会发展的革命性力量，认识到人工智能不仅是提高教学效益的手段，更是培育学生核心素养的重要力量。</span>
        </p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:20.25pt; widows:0">
            <span
                style="font-family:仿宋; font-size:14pt">总而言之参加这样的培训学习，对我本人来说也许不能做到立竿见影，但学习真的可以让我拓宽思路，让自己的教育方法、教育手段更加丰富，同时也对自己在专业教学中一定会起到重要的指导意义，对自己今后的长足长进和改善指明了方向。</span><span
                style="font-family:仿宋; font-size:14pt">       </span><span style="font-family:仿宋; font-size:14pt">——初中 
                刘萍</span></p>
        <h3
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:Calibri; font-size:14pt; font-weight:bold">2.</span><span
                style="font:7.0pt 'Times New Roman'">&#xa0; </span><a name="_Toc22987"></a><a name="_Toc26128053"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">促进课堂形态变革</span><span
                    style="-aw-bookmark-end:_Toc22987"></span></a></h3>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; page-break-after:avoid; text-indent:28pt; widows:0">
            <span
                style="font-family:仿宋; font-size:14pt">现代信息技术手段在教育智能化建设中的深度应用，将使课堂形态发生重大变革。新技术、新媒体和智能终端将为学生提供丰富的认知工具与探究环境，为师生建立更为开放的教学氛围和课堂活动,使传统课堂转变为数字化的“体验馆”和“实验室”，创造与展示各种趋于现实的学习情境。通过在教学过程中采用更加多元的交互协作方式，服务于学习者的知识建构。</span>
        </p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <a name="_Toc26128054"><span
                    style="font-family:仿宋; font-size:14pt">通过学习，使我耳目一新，深深地认识到自己以前那种传统教育教学的师生观已跟不上时代的发展。要想跟上新世纪的教育教学步伐，就要首先转变传统的教育观念，树立正确的师生观，增强热爱教育事业的信念。在教学活动中，师生关系是基础，良好师生关系的建立是教育教学活动获得成功的开始，所以我们必须转变传统的教育中的“惟师是从”的专制型师生观，构建教学双主体之间的相互尊重、相互信任、相互理解的新型的平等、民主的师生关系……我们教师只有从观念上更新，允许学生提出独到的见解，为学生创设一种和谐、宽松的“心理安全”环境和无拘无束的“心理自由”环境，才能创造出师生平等、共乐融融、共享教学民主的氛围。</span></a>
        </p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:right; text-indent:20.25pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">——</span><span
                style="font-family:仿宋; font-size:14pt">银川市永泰小学</span><span style="font-family:仿宋; font-size:14pt"> 
            </span><span style="font-family:仿宋; font-size:14pt">邓芮</span></p>
        <h3
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:Calibri; font-size:14pt; font-weight:bold">3.</span><span
                style="font:7.0pt 'Times New Roman'">&#xa0; </span><a name="_Toc18349"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">建立新型师生关系</span><span
                    style="-aw-bookmark-end:_Toc26128054"></span></a></h3>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span
                style="font-family:仿宋; font-size:14pt">基于智能化教学的师生智能工具的应用设计，逐步弱化讲台的核心位置，教师将融入学生之中，全程面向学生进行教学。这种形式更有利于师生互动，促进平等交流，增强亲近感。新型课堂模式以建构主义学习理论为依据，倡导教育为学习服务。教师是学习服务的提供者、帮助者，教师的任务是为学生学习服务，帮助学生自主学习和知识构建。教学过程将以全体学生为中心，尊重学生学习的主体地位，真正做到“把课堂还给学生，让学生自己成为学习的主人。</span>
        </p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">让学生自己当老师。
                强调能者为师，才能充分体现和实现学生的主体地位，让学生畅所欲言，尽情表述自己对某知识点的理解与想法，带着知识走向学生，不过是"授人以鱼"，带着学生走向知识，才是"授人以渔"。</span></p>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:right; text-indent:28pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt">——</span><span
                style="font-family:仿宋; font-size:14pt">初中</span><span style="font-family:仿宋; font-size:14pt"> 
            </span><span style="font-family:仿宋; font-size:14pt">宋永霞</span></p>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc10822"><span style="font-family:仿宋; font-size:14pt; font-weight:bold">（二）角色效能</span></a></h2>
        <h3
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:Calibri; font-size:14pt; font-weight:bold">1.</span><span
                style="font:7.0pt 'Times New Roman'">&#xa0; </span><a name="_Toc8753"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">面向教师</span></a></h3>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span
                style="font-family:仿宋; font-size:14pt">基于教育智能化解决方案的项目实施，项目组面向教师提供全方位的教学技能培训及资源应用能力培养，全面提升教学效能、提高教学效率。教师可通过平台系统高效管理教学、教研资源，在教师个人职业素养提升的同时，更能带动和促进区域教育资源良性循环，为打造教育资源生态系统助力。同时结合双优云桥平台，实现优质教育资源共建共享。</span>
        </p>
        <h3
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:Calibri; font-size:14pt; font-weight:bold">2.</span><span
                style="font:7.0pt 'Times New Roman'">&#xa0; </span><a name="_Toc11777"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">面向学校</span></a></h3>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:24pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">基于人工智能、大数据、云计算等技术的教育智能化建设项目具有明显的先进性、创新性，是学校着眼于时代发展进程和未来人才需求的重要举措。通过构建“平台、资源、培训”三位一体的智能教育体系，在学校内营造良好的科技创新环境，并提供完善的实践条件。</span>
        </p>
        <h3
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:Calibri; font-size:14pt; font-weight:bold">3.</span><span
                style="font:7.0pt 'Times New Roman'">&#xa0; </span><a name="_Toc30667"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">面向教育管理者</span></a></h3>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:24pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">基于丰富的互联网大数据管理经验、海量教育资源的天然优势，为教育管理者量身定制的教育管理平台及智能化配套服务，可以支持实时查看教师参训情况，根据本校教学大数据分析汇总，掌握详细的学情数据，持续完善教育管理方案。</span>
        </p>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc15080"><span style="font-family:仿宋; font-size:14pt; font-weight:bold">（三）社会效能</span></a></h2>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:24pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">“名校联盟
                双师课堂”项目为银川地区37所项目校打造以双优云桥为核心的线上线下教育体系，为银川培养未来优秀的教师与新型技术人才。项目培训中注重引导教师应更加侧重培养学生的创新性思维、批判性思维、结构化思维、创造力、协作力、表现力等多方面能力。在这样的时代背景下，社会更需要符合时代要求的人才培养计划，来帮助青少年在新的社会就业体系和人生价值坐标系中准确定位自己，向着以人为本、面向未来的方向去发展，成为人工智能时代下所需的人才。</span>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:24pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">推动教育理念更新、模式变革、体系重构，加快推进教育现代化，着力促进教育公平、提高教育质量，办好人民满意的教育，不断增强广大师生和人民群众的获得感。</span>
        </p>
        <h3
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:Calibri; font-size:14pt; font-weight:bold">1.</span><span
                style="font:7.0pt 'Times New Roman'">&#xa0; </span><a name="_Toc6034"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">实现教学与管理减负增效</span></a></h3>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:24pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">在教学方面，先进的课堂工具、学科应用、人工智能的运用，帮助老师获取更多优质教育资源的同时，优化各阶段教学活动，学习情况一目了然。</span>
        </p>
        <h3
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:Calibri; font-size:14pt; font-weight:bold">2.</span><span
                style="font:7.0pt 'Times New Roman'">&#xa0; </span><a name="_Toc14664"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">辐射引领发展</span></a></h3>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:24pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">打造银川互联网+教育示范区，引入资源、名师、课程体系、新兴技术，打造线上与线下教育体系样板，辐射引领周边，协同教育发展。</span>
        </p>
        <h3
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:Calibri; font-size:14pt; font-weight:bold">3.</span><span
                style="font:7.0pt 'Times New Roman'">&#xa0; </span><a name="_Toc28666"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">教育精准扶贫</span></a></h3>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:24pt; widows:0"><span
                style="font-family:仿宋; font-size:14pt">通过项目打造首都与首府的桥梁，引入北京优质资源与名师，跨地区的常态化远程同步课堂、远程教师培训、网络教研、网络教学辅导等，将名校与优质资源辐射效应发挥到最大水平，实现精准教育扶贫。</span>
        </p>
        <h2
            style="font-size:14pt; line-height:172%; margin:13pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:仿宋; font-size:14pt; font-weight:bold">（</span><span
                style="font-family:Arial; font-size:14pt; font-weight:bold">4</span><span
                style="font-family:仿宋; font-size:14pt; font-weight:bold">）</span><a name="_Toc28567"><span
                    style="font-family:仿宋; font-size:14pt; font-weight:bold">项目满意度调查</span></a></h2>
        <p
            style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
            <span
                style="font-family:仿宋; font-size:14pt">为了对项目做出更加客观科学的评价，也是为了更好的了解参训学员的培训状态和想法，项目组从项目整体、平台使用、课程、培训内容、对专业的帮助等多维度出发设计调查问卷，邀请全体参训教师对2020年度项目进行评价，截止目前收到有效问卷310份。问卷情况如下图：</span>
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:仿宋; font-size:14pt">1.项目整体评价：</span><span
                style="background-color:#ffffff; color:#333333; font-family:仿宋; font-size:14pt">您对本次培训是否满意，请您做一个整体评价</span><span
                style="background-color:#ffffff; color:#333333; font-family:仿宋; font-size:14pt">。</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.057.png" width="391" height="216" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：培训满意率情况</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:仿宋; font-size:14pt">2.对培训平台评价：在线上课程学习阶段，您认为平台功能是否便捷</span><span
                style="font-family:仿宋; font-size:14pt">?</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.058.png" width="376" height="201" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：平台使用便捷度评价</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
                style="font-family:仿宋; font-size:14pt">3.课程评价：线上学习课程中哪方面对你的帮助最大？请按照满意度从高到低的顺序对下列选项进行排序</span><span
                style="font-family:仿宋; font-size:14pt">。</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.059.png" width="485" height="184" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：课程内容评价</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
                style="font-family:仿宋; font-size:14pt">4.培训内容评价：您对线上培训讲授的内容，能否与实际工作相结合，对您的专业能力是否有所帮助？</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.060.png" width="457" height="213" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：培训内容评价</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
                style="font-family:仿宋; font-size:14pt">5.培训方式：您最喜欢培训方式是</span><span
                style="font-family:仿宋; font-size:14pt">？</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.061.png" width="468" height="245" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 7.8pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：培训方式评价</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
                style="font-family:仿宋; font-size:14pt">6.培训收获：经过培训，您认为自己在哪些方面进步最大？请按照进步程度由大到小的顺序对下列选项进行排序</span><span
                style="font-family:仿宋; font-size:14pt">。</span></p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><img
                src="./img/25761bad-f73c-436d-bc07-670cf37d7270.062.png" width="554" height="212" alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:仿宋; font-size:14pt">图：培训收获评价</span></p>
    </div>
    <!-- <div class="cnzz" style="display: none;">
        <script src="https://s23.cnzz.com/z_stat.php?id=1277655852&web_id=1277655852" language="JavaScript"></script>
    </div> -->
    <!-- <div class="docpe" style="position: absolute;color: white;margin-left:-450;">
        <a target="_blank" href="http://www.docpe.com">档铺网——在线文档免费处理</a>
    </div> -->
			</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  watch: {},
  methods: {
	// 	handle(groupId) {
    //     this.$router.push({path:"/resourcescenter",query:{type:'teacher',groupId:groupId}});
    // },
    handle(groupId) {
      this.$router.push({
        path: "/resources",
        // query: { type: "yin",groupId:groupId },
      });
    },
	},
  mounted() {},
  components: {},
};
</script>

<style lang="less" scoped>
.resource-center-preview {
  
	min-width: 1100px;
  padding: 20px 20px;
  .tabs-box {
		p {
			font-family: Source Han Sans CN !important;
		}
		span {
			font-family: Source Han Sans CN !important;
		}
    display: flex;
		justify-content: space-between;
		// height: 80vh;
		width: 1200px;
		margin: 0 auto;
		
    .tabs-left {
			border: 1px solid #f8f8f8;
			// box-shadow: 0px 5px 29px 0px rgb(22 115 255 / 16%);
			border-radius: 2px;
			width: 200px;
			padding:20px 40px;
			font-size: 14px;
			height: 80vh;
			overflow-y: auto;
		background: #fafafa;


    }
    .tabs-right {
			width: 100%;
			padding:20px 40px;
			margin-left:20px; 
			background: #fafafa;
			flex: 1;
			border: 1px solid #f8f8f8;
			border-radius: 2px;
			height: 80vh;
			overflow-y: auto;
			// width: 200px;
			.font-weight {
				font-family:仿宋;
				color: red;
				font-weight: bold; 
				font-size:16pt;
				cursor:pointer;
			}
      
    }
  }
}
</style>
